import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

import { CopyTextModule } from '@directives/copy-text/copy-text.module';

import { LinkCardComponent } from './link-card.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [LinkCardComponent],
  exports: [LinkCardComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    CopyTextModule,
    MatTooltipModule,
  ]
})
export class LinkCardModule {}
