export enum PermissionsEnum {
  RoleRead = 'role-read',
  RoleCreate = 'role-create',
  RoleUpdate = 'role-update',
  RoleDelete = 'role-delete',
  PermissionAdd = 'permission-add',

  UserCreate = 'user-create',
  UserRead = 'user-read',
  UserUpdate = 'user-update',
  UserDelete = 'user-delete',
  UserImport = 'user-import',
  ExportUsers = 'export-users',
  ExportUserStats = 'export-user-stats',

  UserGroupCreate = 'user-group-create',
  UserGroupRead = 'user-group-read',
  UserGroupUpdate = 'user-group-update',
  UserGroupDelete = 'user-group-delete',

  GeneralUpdate = 'general-update',
  EMVUpdate = 'emv-update',
  EmailUpdate = 'email-update',
  IntegrationFacebook = 'integration-facebook',
  IntegrationLinkedin = 'integration-linkedin',
  IntegrationFalcon = 'integration-brandwatch',
  UpdateNotification = 'update-notifications',
  SocialIntegration = 'settings-social-integration',
  ContentHub = 'settings-content-hub',
  SetActiveNetworks = 'set-active-networks',

  SourceCreate = 'source-create',
  SourceRead = 'source-read',
  SourceUpdate = 'source-update',
  SourceDelete = 'source-delete',
  SourceCreateFacebook = 'source-create-facebook',
  SourceCreateLinkedin = 'source-create-linkedin',
  SourceCreateRss = 'source-create-rss',
  SourceCreateFalcon = 'source-create-brandwatch',

  CreatePost = 'create-post',
  ExportStatistics = 'export-statistics',
  ViewPostList = 'view-post-list',
  ViewSinglePost = 'view-single-post',
  DeletePost = 'delete-post',

  ViewEngagementInsights = 'insights-engagement-view',
  ExportEngagementInsights = 'insights-engagement-export',
  ViewPostEffectivenessInsights = 'insights-post-effectiveness-view',
  ViewUsergroupInsights = 'insights-usergroup-stats-view',

  CreateScoreboard = 'create-scoreboard',
  DeleteOwnScoreboards = 'delete-own-scoreboards',
  DeleteAllScoreboards = 'delete-all-scoreboards',

  WelcomeEmail = 'welcome-email',
  EditWelcomeEmail = 'edit-welcome-email',

  Fullprivilege = 'full-privilege',
}


export const UserPermissions = {
  [PermissionsEnum.RoleRead]: false,
  [PermissionsEnum.RoleCreate]: false,
  [PermissionsEnum.RoleUpdate]: false,
  [PermissionsEnum.RoleDelete]: false,
  [PermissionsEnum.PermissionAdd]: false,

  [PermissionsEnum.UserCreate]: false,
  [PermissionsEnum.UserRead]: false,
  [PermissionsEnum.UserUpdate]: false,
  [PermissionsEnum.UserDelete]: false,
  [PermissionsEnum.UserImport]: false,
  [PermissionsEnum.ExportUsers]: false,
  [PermissionsEnum.ExportUserStats]: false,

  [PermissionsEnum.UserGroupCreate]: false,
  [PermissionsEnum.UserGroupRead]: false,
  [PermissionsEnum.UserGroupUpdate]: false,
  [PermissionsEnum.UserGroupDelete]: false,

  [PermissionsEnum.GeneralUpdate]: false,
  [PermissionsEnum.EMVUpdate]: false,
  [PermissionsEnum.EmailUpdate]: false,
  [PermissionsEnum.IntegrationFacebook]: false,
  [PermissionsEnum.IntegrationLinkedin]: false,
  [PermissionsEnum.IntegrationFalcon]: false,
  [PermissionsEnum.UpdateNotification]: false,
  [PermissionsEnum.SocialIntegration]: false,
  [PermissionsEnum.ContentHub]: false,
  [PermissionsEnum.SetActiveNetworks]: false,

  [PermissionsEnum.SourceCreate]: false,
  [PermissionsEnum.SourceRead]: false,
  [PermissionsEnum.SourceUpdate]: false,
  [PermissionsEnum.SourceDelete]: false,
  [PermissionsEnum.SourceCreateFacebook]: false,
  [PermissionsEnum.SourceCreateLinkedin]: false,
  [PermissionsEnum.SourceCreateRss]: false,
  [PermissionsEnum.SourceCreateFalcon]: false,

  [PermissionsEnum.CreatePost]: false,
  [PermissionsEnum.ExportStatistics]: false,
  [PermissionsEnum.ViewPostList]: false,
  [PermissionsEnum.ViewSinglePost]: false,
  [PermissionsEnum.DeletePost]: false,

  [PermissionsEnum.ViewEngagementInsights]: false,


  [PermissionsEnum.ExportEngagementInsights]: false,
  [PermissionsEnum.ViewPostEffectivenessInsights]: false,
  [PermissionsEnum.ViewUsergroupInsights]: false,


  [PermissionsEnum.CreateScoreboard]: false,
  [PermissionsEnum.DeleteOwnScoreboards]: false,
  [PermissionsEnum.DeleteAllScoreboards]: false,


  [PermissionsEnum.WelcomeEmail]: false,
  [PermissionsEnum.EditWelcomeEmail]: false,


  [PermissionsEnum.Fullprivilege]: false,
}

export const enum UserRolesEnum {
  SuperAdmin = 'super-admin',
  SiteAdmin = 'site-admin',
}

export const UserRoles = {
  [UserRolesEnum.SuperAdmin]: false,
  [UserRolesEnum.SiteAdmin]: false,
}