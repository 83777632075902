export default {
  // Magic Login Page
  "Request Magic Login": "",
  "To make life easy we've skipped lengthy and difficult passwords. Enter your email and check your inbox for an email with a login link.": "",
  "Email Address": "",
  "Email is required.": "",
  "Invalid Email": "",
  "Send magic link": "",
  "Welcome to your<br/>Content Hub": "",
  "This is your personal Content Hub, where you will find our latest news, social media posts, and job listings that you can share with your network. Enter your email address to gain access.": "Bu, en son haberlerimizi, sosyal medya paylaşımlarımızı ve ağınızla paylaşabileceğiniz iş ilanlarını bulabileceğiniz kişisel İçerik Hub'ınızdır. E-posta adresinizi girin ve erişim sağlayın.",

  // Magic Verify Page
  "Verifying user!": "",

  // Signup Page
  "Signup": "",
  "Signin": "",
  "Sign In": "",
  "Already have an account?": "",
  "First name": "",
  "Last name": "",
  "Email address": "",
  "Phone": "",
  "Department": "",
  "Department is required": "",
  "I agree to the": "",
  "Welcome to": "",
  "Create your account": "",
  "Invalid phone number": "",
  "Allowed domains i.e;": "",
  "Email address or Phone number is required": "",
  "Invalid domain": "",
  "Please enter a valid email address": "",
  "Last name is required": "",
  "Whitespaces are not allowed": "",
  "First name is required": "",

  // Welcome modal
  "Hi {{ userName }}": "Merhaba {{ userName }}",
  "Welcome to our social media hub. Take part in sharing all our great stories, news, job posts and more.": "Sosyal medya merkezimize hoş geldiniz. Tüm harika hikayelerimizi, haberlerimizi, iş ilanlarımızı ve daha fazlasını paylaşmaya katılın.",
  "Every share counts.": "",
  "Get started right here": "Hemen buradan başlayın",
};
