import { User } from './user';
import { ITag } from '@lib/tag.interface';
import { NetworkProfile, Tag } from '@sociuu/interfaces';

export enum SOURCES {
  RSS = 'rss',
  EMPLY = 'emply',
  SOCIAL = 'social',
  FALCON = 'falcon',
  PERSONIO = 'personio',
  FACEBOOK = 'facebook',
  LINKEDIN = 'linkedin',
}

export interface SourceApiResponse {
  data: {
    sources: Source[];
    types: Types[];
  };
}

export interface Types {
  created_at: string;
  id: number;
  name: string;
  updated_at: string;
}

export interface Source {
  id?: number;
  type?: SOURCES;
  source_type: string;
  url: string;
  rss_url: string;
  client_name: string;
  status: string;
  title: string;
  description: string;
  content_visible: number;
  import_tags: boolean;
  available_for_days: number;
  available_until: string;
  video_analytics_network_id: number;
  expires_at_deadline: number;
  user_social_token_id?: number;
  is_deleted: number;
  client_id: number;
  page_id: number;
  added_by: number;
  last_updated_by: number;
  content_count: number;
  tags?: Tag[] | ITag[];
  networks?: NetworkProfile[];
  created_at: string;
  updated_at: string;
  first_name?: string;
  last_name?: string;
  source_type_id: number;
  in_sociuuhub: number;
  sociuuhub_expires_at: number;
  content_expires_after_days: number;
  falcon_filters: FalconFilters;
  auto_archive?: boolean;
  auto_unarchive?: boolean;
  account_domain?: string;
  account_name?: string;
  job_details_url?: string;
  integration_expired?: boolean | null;
  integrated_to?: User;
  bw_prevent_duplicates?: boolean;
  auto_send_to_bw?: 1 | 0;
  import_tags_setting?: 'prepend' | 'merge';
  apply_tags_to_content?: boolean;
  force_fallback_image?: boolean | 1 | 0;
  auto_archived_expired_contents?: boolean;
  direct_share?: boolean;
}

export interface FalconFilters {
  falcon_channels: string[];
  falcon_labels: string[];
  falcon_networks: string[];
  falcon_statuses: number[];
  falcon_visibility: string;
}
