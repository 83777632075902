import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckUserInfoComponent } from './check-user-info.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [CheckUserInfoComponent],
  imports: [CommonModule, MatProgressSpinnerModule],
  exports: [CheckUserInfoComponent],
})
export class CheckUserInfoModule {}
