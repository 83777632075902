import { Observable } from 'rxjs';

import { CoreComponent } from '@sociuu/core';

import { ILogin } from '@lib/auth-token.interface';
import { RefreshTokenDto } from '@sociuu/interfaces';

export abstract class AbstractAuthService extends CoreComponent {
  abstract login(
    email: string,
    password: string
  ): Observable<ILogin>

  abstract logout(): void;

  abstract refreshUserToken(): Observable<RefreshTokenDto>;

  abstract getTokenFromStorage(): string;
}
