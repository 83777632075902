import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HostnamePipe } from './hostname.pipe';

@NgModule({
  declarations: [HostnamePipe],
  imports: [CommonModule],
  exports: [HostnamePipe],
})
export class HostnameModule {}
