import * as dayjs from 'dayjs';
import { takeUntil } from 'rxjs/operators';
import * as relativeTime from 'dayjs/plugin/relativeTime';

import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';

import { CoreComponent } from '@sociuu/core';

import { DashboardService } from '@sociuu/modules';

import { AuthService } from '@admin/auth/services/auth.service';

import { ClientInfoDto, Notification, UserInfoDto } from '@sociuu/interfaces';

dayjs.extend(relativeTime);

@Component({
  selector: 'sociuu-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent extends CoreComponent implements OnInit {
  @Input('snav') public sideNav: any;

  userInfo: UserInfoDto;
  openedNotification = 0;
  clientInfo: ClientInfoDto;
  numOfUnreadNotifications = 0;
  systemNotifications: Notification[] = [];
  superAdminNotifications: Notification[] = [];

  constructor(
    private router: Router,
    private authService: AuthService,
    private dashboardService: DashboardService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.getClientInfo();
    this.getNotifications();
  }

  public logout(): void {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  public markNotificationAsRead(notification: Notification, type: string, index: number): void {
    this.dashboardService
      .markNotificationAsRead(notification.client_notification_id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        if (type === 'system') {
          this.systemNotifications.map((item) => {
            if (
              item.client_notification_id === notification.client_notification_id &&
              this.systemNotifications[index].status === 'unread'
            ) {
              this.systemNotifications[index].status = 'read';
              this.numOfUnreadNotifications--;
            }
          });
          this.router.navigateByUrl(`/posts/details/${notification.post_id}`);
        } else {
          this.superAdminNotifications.map((item) => {
            if (item.client_notification_id === notification.client_notification_id) {
              this.superAdminNotifications[index].status = 'read';
            }
          });
        }
      });
  }

  public timeInterval(time: string): string {
    return dayjs(time).fromNow();
  }

  public openNotification(id: number): void {
    if (this.openedNotification === id) this.openedNotification = 0;
    else this.openedNotification = id;
  }

  private getClientInfo(): void {
    if (this.authService.clientInfo.value) this.clientInfo = this.authService.clientInfo.value;
    if (this.authService.currentUser.value) this.userInfo = this.authService.currentUser.value;
  }

  private getNotifications(): void {
    this.dashboardService
      .getNotificationList()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        this.systemNotifications = response.data.system;
        this.superAdminNotifications = response.data.superadmin;
        this.systemNotifications.map((notification) => {
          if (notification.status === 'unread') this.numOfUnreadNotifications++;
        });
        this.superAdminNotifications.map((notification) => {
          if (notification.status === 'unread') this.numOfUnreadNotifications++;
        });
      });
  }

}
