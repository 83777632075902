export default {
  // Magic Login Page
  "Request Magic Login": "Solicita tu acceso mágico",
  "To make life easy we've skipped lengthy and difficult passwords. Enter your email and check your inbox for an email with a login link.": "Para hacerte la vida más fácil hemos eliminado las contraseñas largas y difíciles. Ingresa tu correo electrónico corporativo y revisa tu bandeja de entrada para obtener acceso.",
  "Email Address": "Correo electrónico",
  "Email is required.": "Correo electrónico requerido.",
  "Invalid Email": "Envía acceso mágico",
  "Send magic link": "Envía acceso mágico",
  "Welcome to your<br/>Content Hub": "Bienvenida a tu</br>Centro de Contenidos",
  "This is your personal Content Hub, where you will find our latest news, social media posts, and job listings that you can share with your network. Enter your email address to gain access.": "Tu participación cuenta. Comparte noticias e información valiosa con tu círculo de contactos para sumar tus esfuerzos a los objetivos del equipo.",

  // Magic Verify Page
  "Verifying user!": "¡Verifica al usuario!",

  // Signup Page
  "Signup": "",
  "Signin": "",
  "Sign In": "",
  "Already have an account?": "",
  "First name": "",
  "Last name": "",
  "Email address": "",
  "Phone": "",
  "Department": "",
  "Department is required": "",
  "I agree to the": "",
  "Welcome to": "",
  "Create your account": "",
  "Invalid phone number": "",
  "Allowed domains i.e;": "",
  "Email address or Phone number is required": "",
  "Invalid domain": "",
  "Please enter a valid email address": "",
  "Last name is required": "",
  "Whitespaces are not allowed": "",
  "First name is required": "",

  // Welcome modal
  "Hi {{ userName }}": "Hola {{ userName }}",
  "Welcome to our social media hub. Take part in sharing all our great stories, news, job posts and more.": "Bienvenida a nuestro Centro de Contenido. Participa compartiendo todas nuestras grandes historias, noticias, publicaciones de anuncios y más.",
  "Every share counts.": "Cada vez que compartes cuenta.",
  "Get started right here": "Empieza aquí mismo.",
};
