import {
  ICON_X,
  VK_ICON,
  XING_ICON,
  RIGHT_CARET,
  FACEBOOK_ICON,
  SECURITY_ICON,
  UNDRAW_WARNING,
  USER_CIRCLE_ICON,
  TAGS_ICON_STYLE_1,
  TAGS_ICON_STYLE_2,
  TEMPLATE_WELCOME_EMAIL,
  LINKEDIN_144,
  LINKEDIN_48,
  LINKEDIN,
  WIFI,
  RSS_FEED,
} from "./icons.const";

export interface IIcons {
  name: string;
  svg: string;
}

export const ICONS: IIcons[] = [
  {
    name: 'security',
    svg: SECURITY_ICON,
  },
  {
    name: 'right-caret',
    svg: RIGHT_CARET,
  },
  {
    name: 'user-circle',
    svg: USER_CIRCLE_ICON,
  },
  {
    name: 'facebook',
    svg: FACEBOOK_ICON,
  },
  {
    name: 'xing',
    svg: XING_ICON,
  },
  {
    name: 'vk',
    svg: VK_ICON,
  },
  {
    name: 'tags',
    svg: TAGS_ICON_STYLE_1,
  },
  {
    name: 'tags-2',
    svg: TAGS_ICON_STYLE_2,
  },
  {
    name: 'welcome-email-template',
    svg: TEMPLATE_WELCOME_EMAIL,
  },
  {
    name: 'undraw-warning',
    svg: UNDRAW_WARNING,
  },
  {
    name: 'x-mini',
    svg: ICON_X,
  },
  {
    name: 'linkedin',
    svg: LINKEDIN,
  },
  {
    name: 'linkedin-48',
    svg: LINKEDIN_48,
  },
  {
    name: 'linkedin-144',
    svg: LINKEDIN_144,
  },
  {
    name: 'wifi',
    svg: WIFI,
  },
  {
    name: 'rss_feed',
    svg: RSS_FEED,
  },
];
