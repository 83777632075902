import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AppService } from '@app/app.service';

@Injectable({
  providedIn: 'root',
})
export class CountSettingsResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(private _appService: AppService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this._appService.getCountSettings();
  }
}
