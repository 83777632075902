export default {
  // Magic Login Page
  "Request Magic Login": "Vraag een Magic link aan",
  "To make life easy we've skipped lengthy and difficult passwords. Enter your email and check your inbox for an email with a login link.": "Om het leven makkelijker te maken, hebben we lange en moeilijke wachtwoorden overgeslagen. Voer je e-mailadres in en controleer je inbox voor een e-mail met een inloglink.",
  "Email Address": "e-mailadres",
  "Email is required.": "E-mail is vereist.",
  "Invalid Email": "Email is ongeldig.",
  "Send magic link": "Stuur magic link",
  "Welcome to your<br/>Content Hub": "Welkom bij jouw</br>Content Hub",
  "This is your personal Content Hub, where you will find our latest news, social media posts, and job listings that you can share with your network. Enter your email address to gain access.": "Dit is jouw persoonlijke Content Hub, waar je ons laatste nieuws, berichten van sociale media en vacatures kunt vinden die je kunt delen met je netwerk. Voer je e-mailadres in om toegang te krijgen.",

  // Magic Verify Page
  "Verifying user!": "Gebruiker verifiëren!",

  // Signup Page
  "Signup": "",
  "Signin": "",
  "Sign In": "",
  "Already have an account?": "",
  "First name": "",
  "Last name": "",
  "Email address": "",
  "Phone": "",
  "Department": "",
  "Department is required": "",
  "I agree to the": "",
  "Welcome to": "",
  "Create your account": "",
  "Invalid phone number": "",
  "Allowed domains i.e;": "",
  "Email address or Phone number is required": "",
  "Invalid domain": "",
  "Please enter a valid email address": "",
  "Last name is required": "",
  "Whitespaces are not allowed": "",
  "First name is required": "",

  // Welcome modal
  "Hi {{ userName }}": "Hi {{ userName }}",
  "Welcome to our social media hub. Take part in sharing all our great stories, news, job posts and more.": "Hier blijf je op de hoogte van relevante content die je eenvoudig op social media kan posten.",
  "Every share counts.": "Elke post telt.",
  "Get started right here": "Start direct",
};
