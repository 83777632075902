import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-alert-banner',
  templateUrl: './alert-banner.component.html',
  styleUrls: ['./alert-banner.component.scss']
})
export class AlertBannerComponent {
  @Output() private closeBanner: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  public close(): void {
    this.closeBanner.emit();
  }
}
