import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { MatSnackBarModule } from '@angular/material/snack-bar';

import { LoadImageModule } from 'app/shared';
import { AuthModule } from './auth/auth.module';
import { IconsModule } from 'app/core/icons/icons.module';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';

import { ApiRequestInterceptor } from './interceptors/api-request-interceptor';

@NgModule({
  imports: [
    AuthModule,
    IconsModule,
    LoadImageModule,
    HttpClientModule,
    MatSnackBarModule,
    TranslocoCoreModule,
  ],
})
export class CoreModule {
  /**
   * Constructor
   */
  constructor(
    @Optional() @SkipSelf() parentModule?: CoreModule
  ) {
    // Do not allow multiple injections
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
    }
  }
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ApiRequestInterceptor,
          multi: true,
        },
      ],
    };
  }
}
