<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>
<!-- Navigation -->
<fuse-vertical-navigation
  class="dark bg-gray-900 print:hidden"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation?.default"
  [opened]="!isScreenSmall"
>
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <!-- Logo -->
    <div class="flex items-center h-20 pb-0 px-7">
      <!-- Light version -->
      <img class="dark:hidden w-20" [src]="clientInfo?.company_logo" alt="Logo image" />
      <!-- Dark version -->
      <img class="hidden dark:flex w-20" [src]="clientInfo?.company_logo" alt="Logo image" />
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div #wrapperElement class="flex flex-col flex-auto w-full min-w-0 overflow-y-auto">
  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-[1] shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
  >
    <!-- Navigation toggle button -->
    <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
    </button>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <!-- <languages></languages> -->
      <!-- <fuse-fullscreen class="hidden md:block"></fuse-fullscreen> -->
      <!-- <search [appearance]="'bar'"></search> -->
      <!-- <shortcuts></shortcuts> -->
      <!-- <messages></messages> -->
      <notifications></notifications>
      <!-- <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()"
            >
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button> -->
      <user></user>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <router-outlet></router-outlet>
  </div>

  <!-- Footer -->
  <div
    class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 border-t bg-card dark:bg-transparent print:hidden"
  >
    <span class="font-medium text-secondary">Sociuu &copy; {{ currentYear }}</span>
  </div>
</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->
