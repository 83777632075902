import { cloneDeep, isNil } from 'lodash';
import { Observable, ReplaySubject, map, mergeMap, tap } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppService } from '@app/app.service';
import { AuthService } from '@app/modules/admin/auth/services/auth.service';

import { ClientInfoDto } from '@sociuu/interfaces';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { PermissionsEnum, UserPermissions, UserRoles, UserRolesEnum } from '@app/app.permissions';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private navigation: Navigation;
  private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

  /**
   * Constructor
   */
  constructor(private appService: AppService, private _httpClient: HttpClient, private authService: AuthService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for navigation
   */
  get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable().pipe(
      tap((navigation: Navigation) => {
        this.navigation = navigation;
      }),

      mergeMap(() => {
        return this.authService.clientInfo.pipe(
          map((res: ClientInfoDto) => {
            if (this.navigation) {
              const createNativeContent: FuseNavigationItem = this.navigation.default?.find((nav) => nav.id === 'create-native-content');
              createNativeContent.disabled = !this.authService.isSocialConnectFeatureEnabled;
              this.navigation = cloneDeep(this.navigation);
            }
            this.navigation = cloneDeep(this.navigation);
          }),
          map(() => this.navigation)
        );
      }),

      mergeMap(() => {
        return this.appService.getUserCount().pipe(
          tap((res: number) => {
            if (!isNil(res) && this.navigation) {
              const userManagementMenu: FuseNavigationItem = this.navigation.default?.find((nav) => nav.id === 'user-managment');
              const usersMenu: FuseNavigationItem = userManagementMenu?.children.find((nav) => nav.id === 'users');
              const allUsersMenu: FuseNavigationItem = usersMenu?.children.find((nav) => nav.id === 'users.all-users');
              allUsersMenu.badge.title = '' + res;
              this.navigation = cloneDeep(this.navigation);
            }
          }),
          map(() => this.navigation)
        );
      }),

      mergeMap(() => {
        return this.authService.currentUser.pipe(
          tap((res) => {
            if (res && this.navigation) {
              const userManagementMenu: FuseNavigationItem = this.navigation.default?.find((nav) => nav.id === 'user-managment');
              const usersMenu: FuseNavigationItem = userManagementMenu?.children.find((nav) => nav.id === 'users');
              const userImport: FuseNavigationItem = usersMenu?.children.find((nav) => nav.id === 'users.import');
              if (UserPermissions[PermissionsEnum.Fullprivilege] || UserPermissions[PermissionsEnum.UserImport])
                userImport.disabled = false;
              this.navigation = cloneDeep(this.navigation);
            }
          }),

          tap((res) => {
            if (this.navigation) {
              const rolesAndPermissions = this.navigation?.default
                ?.find((nav) => nav.id == 'settings')
                .children?.find((nav) => nav.id == 'settings.roles-and-permission-v2');
              if (UserPermissions[PermissionsEnum.Fullprivilege]) rolesAndPermissions.disabled = false;
              this.navigation = cloneDeep(this.navigation);
            }
          }),
          map(() => this.navigation)
        );
      }),

      mergeMap(() => {
        return this.authService.isActimoIntegrationEnabled.pipe(
          map((res: boolean) => {
            if (this.navigation) {
              const userManagementMenu: FuseNavigationItem = this.navigation.default?.find((nav) => nav.id === 'user-managment');
              const usersMenu: FuseNavigationItem = userManagementMenu?.children.find((nav) => nav.id === 'users');
              const syncUsersMenu: FuseNavigationItem = usersMenu?.children.find((nav) => nav.id === 'users.sync-users');
              syncUsersMenu.disabled = !res;
            }
            this.navigation = cloneDeep(this.navigation);
          }),
          map(() => this.navigation)
        );
      }),
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all navigation data
   */
  get(): Observable<Navigation> {
    return this._httpClient.get<Navigation>('api/common/navigation').pipe(
      tap((navigation) => {
        this._navigation.next(navigation);
      })
    );
  }
}
