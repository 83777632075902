import * as Sentry from "@sentry/angular-ivy";

import { environment } from '@utility/app.util';


function captureExceptionFunc(error): void {
  // Sentry.captureException(new Error(error));
}

function sentryInitFunc(): void {
  const sentryTracesSampleRate: number = environment.env === 'local' ? 0.2 : 1.0;

  Sentry.init({
    dsn: environment.sentryDSN,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ["*"],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    ignoreErrors: ["Error: Http failure response", "Error: Https failure response"],
    tracesSampleRate: sentryTracesSampleRate,
    debug: false, // enable debug for local development
    enabled: environment?.env === 'local' ? false : true,
    attachStacktrace: true,
    environment: environment.env,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  });
}

function setSentryUserFunc({ email, id, username }): void {
  Sentry.setUser({ email, id, username });
}

function removeSentryUserFunc(): void {
  Sentry.setUser(null);
}

export const setSentryUser = addTryCatch(setSentryUserFunc);
export const removeSentryUser = addTryCatch(removeSentryUserFunc);
export const sentryInit = addTryCatch(sentryInitFunc);
export const captureException = addTryCatch(captureExceptionFunc);

function addTryCatch(fn: Function): Function {
  return function (...args: any[]) {
    try {
      return fn(...args);
    } catch (error) {
      console.error("Error occurred in sentry:", error);
      return undefined;
    }
  };
}

