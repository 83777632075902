import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { MatSnackBar } from '@angular/material/snack-bar';

import { IconSnackBarComponent } from '@sociuu/utility/index.icon-snack-bar';

import { AuthService } from '@sociuu/admin/auth/services/auth.service';
import { AuthService as SociuuhubAuth } from '@modules/sociuuhub/modules/auth/services/auth.service';

import { getValidationErrorMessage } from '@app/utility/app.util';
import { captureException } from '@app/utility/sentry.utils';

@Injectable()
export class ApiRequestInterceptor implements HttpInterceptor {
  private helper = new JwtHelperService();

  constructor(
    private router: Router,
    private authService: AuthService,
    private matSnackBar: MatSnackBar,
    private sociuuhubAuth: SociuuhubAuth,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token: string = '';
    if (request.url.includes('/sociuuhub/become') && !this.authService.isSociuuhub) token = localStorage.getItem('token');
    else if (request.url.includes('/sociuuhub') || this.authService.isSociuuhub) {
      token = localStorage.getItem('tokenUser');
      if (this.authService.currentTokenUser && this.authService.currentTokenUser !== token) setTimeout(() => this.sociuuhubAuth.forceRefresh(), 0);
      else this.authService.currentTokenUser = token;
    } else token = localStorage.getItem('token');
    if (
      token &&
      !request.url.includes('getPreview') &&
      !request.url.includes('/v2/magic-login')
    ) request = this.addToken(request, token);

    let req: HttpRequest<any> = request;
    if (!request.headers) {
      req = request.clone({
        headers: request.headers.set('Content-Type', 'application/json').set('Access-Control-Allow-Origin', '*'),
      });
    }

    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          const errorMessage = error.error.message
            ? error.error.message
            : error.error.messages
            ? error.error.messages?.[0]
            : error.message
            ? error.message
            : error.error;

          if ([400, 403].includes(error.status)) {
            captureException(error);
            return throwError(() => getValidationErrorMessage(error.error));
          }

          if (error.status === 404) {
            captureException(error);
            this.openCustomSnackBar(getValidationErrorMessage(error.error), 'danger');
            return throwError(() => error.error);
          }

          if ([401, 423].includes(error.status)) {
            captureException(error);
            this.authService.getClientInfo().subscribe();
            this.openCustomSnackBar(getValidationErrorMessage(error.error) || 'You are unauthorized', 'danger');
            if (this.helper.decodeToken(token)?.sociuuhub_login || this.router.url.includes('/sociuuhub')) {
              localStorage.removeItem('tokenUser');
              this.router.navigate(['/sociuuhub/login']);
            } else {
              localStorage.removeItem('token');
              if (!this.router.url.includes('/public/')) {
                this.router.navigate(['/login']);
              }
            }
          }

          if ([422, 503].includes(error.status)) {
            captureException(error);
            this.openCustomSnackBar(getValidationErrorMessage(error.error), 'danger');
            return throwError(() => error.error);
          }

          if ([500].includes(error.status)) {
            this.openCustomSnackBar(error.message, 'danger');
            return throwError(() => error.error);
          }

          const customError: Error = new Error(error as any);
          customError.message = errorMessage;
          customError['status'] = error.status;
          captureException(customError);
          return throwError(() => customError);

        } else {
          captureException(error);
          return throwError(() => new Error(error));
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private openCustomSnackBar(message: string, icon: string): void {
    this.matSnackBar.openFromComponent(IconSnackBarComponent, {
      data: {
        message: message,
        icon: icon,
      },
    });
  }
}
