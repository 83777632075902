import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, Router, RouterModule } from '@angular/router';

import { IntercomModule } from 'ng-intercom';
import { MarkdownModule } from 'ngx-markdown';
import { CoreModule } from 'app/core/core.module';
import { LayoutModule } from 'app/layout/layout.module';
import { TranslocoCoreModule } from './core/transloco/transloco.module';
import { RegisterIconsModule } from './shared/register-icons/register-icons.module';

import { FuseModule } from '@fuse';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { FuseConfigModule } from '@fuse/services/config';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appReducer } from './modules/sociuuhub/store/app.state';
import { FuseAlertModule } from '@fuse/components/alert';

import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';

import { AppComponent } from 'app/app.component';
import { IconSnackBarComponent } from '@sociuu/utility/icon-snack-bar';
import { AlertBannerComponent } from './alert-banner/alert-banner.component';

import { appRoutes } from 'app/app.routing';
import { mockApiServices } from 'app/mock-api';
import { CookieService } from 'ngx-cookie-service';
import { appConfig } from 'app/core/config/app.config';

import * as Sentry from "@sentry/angular-ivy";


const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
};

@NgModule({
  declarations: [AppComponent, IconSnackBarComponent, AlertBannerComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),

    // Fuse, FuseConfig & FuseMockAPI
    FuseModule,
    FuseAlertModule,
    FuseConfigModule.forRoot(appConfig),
    FuseMockApiModule.forRoot(mockApiServices),

    // Core module of your application
    // CoreModule,
    CoreModule.forRoot(),

    // Layout module of your application
    LayoutModule,

    // 3rd party modules that require global configuration via forRoot
    MarkdownModule.forRoot({}),

    MatIconModule,
    MatMenuModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,

    IntercomModule,
    RegisterIconsModule,
    TranslocoCoreModule.forRoot(),
  ],
  
  providers: [
    CookieService,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 3000, panelClass: 'snackbar' },
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false, // set to true to show a Sentry feedback dialog
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  entryComponents: [IconSnackBarComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
