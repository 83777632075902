import { merge } from 'lodash-es';

import { FormGroup } from '@angular/forms';
import { Injectable, TemplateRef } from '@angular/core';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ConfirmationModalComponent } from './confirmation-modal.component';

import { DEFAULT_CONFIG } from './util';
import { FuseConfirmationConfig } from '@fuse/services/confirmation';

export interface IConfirmationModalConfig extends FuseConfirmationConfig {
  isConfirmActionDisabled?: boolean;
  template?: {
    title?: string;
    value?: string;
    customTemplateForm?: FormGroup;
    templateRef?: TemplateRef<HTMLElement>;
    templateType: 'custom' | 'confirmInput' | 'checkbox' | 'slideToggle';
  };
}

@Injectable({
  providedIn: 'root',
})
export class ConfirmationModalService {
  private _defaultConfig: IConfirmationModalConfig = DEFAULT_CONFIG;

  /**
   * Constructor
   */
  constructor(private _matDialog: MatDialog) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  public open(config: IConfirmationModalConfig = {}): MatDialogRef<ConfirmationModalComponent> {
    // Merge the user config with the default config
    const userConfig = merge({}, this._defaultConfig, config);

    // Open the dialog
    return this._matDialog.open(ConfirmationModalComponent, {
      width: '600px',
      data: userConfig,
      autoFocus: false,
      disableClose: !userConfig.dismissible,
      panelClass: 'fuse-confirmation-dialog-panel',
    });
  }
}
