import { environment } from '@utility/app.util';

import moment from 'moment';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription, interval } from 'rxjs';


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { DistributionChannelsResponseDto } from '@sociuu/interfaces';
import { ICountsSettings, ICountsSettingsServer } from '@lib/count.interface';
import { IUserSyncStatus } from './modules/admin/sync-users/user-sync.interface';

import { IconSnackBarComponent } from './utility/index.icon-snack-bar';
import { LAST_SYNC_FORMAT_12HR, LAST_SYNC_FORMAT_24HR, is12HoursFormat } from './utility/date-time';

const REFRESH_STATUS_POLLING_TIMEOUT: number = 90_000;
const REFRESH_STATUS_POLLING_INTERVAL: number = 30_000;

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public environment: any = environment;

  private currentWindow: Window = null;
  private _countSettings: BehaviorSubject<ICountsSettings> = new BehaviorSubject(null);
  private _userCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public syncInProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public lastSynchronised$: BehaviorSubject<string> = new BehaviorSubject<string>('You havn\'t sync yet');

  public get countSettings(): BehaviorSubject<ICountsSettings> {
    return this._countSettings;
  }

  public get usersCount(): number {
    return this.countSettings?.value?.navigation?.userCount;
  }

  public get currencySymbol(): string {
    return this.countSettings.value?.settings?.currencySymbol;
  }

  constructor(private http: HttpClient, private snackBar: MatSnackBar) { }

  public getUserCount(): Observable<number> {
    return this._userCountSubject.asObservable();
  }

  public getSyncInProgress(): Observable<boolean> {
    return this.syncInProgress$.asObservable();
  }

  public getLastSynchronised(): Observable<string> {
    return this.lastSynchronised$.asObservable();
  }

  public updateUserCount(): void {
    this.http.get(`${environment.apiUrl}/counts-settings`).subscribe({
      next: (response: { data: ICountsSettingsServer, success: boolean }) => {
        this._userCountSubject.next(response.data.navigation.userCount);
      },
      error: (err) => {
        console.error('Error updating user count:', err);
      }
    })
  }

  public openWindow(url: string, target: string): void {
    this.currentWindow = window.open(url, target, 'location = yes, height = 570, width = 900, scrollbars = yes,status = yes');
  }

  public closeWindow(): void {
    if (this.currentWindow) {
      this.currentWindow.close();
    }
  }

  public getDistributions(): Observable<DistributionChannelsResponseDto> {
    return this.http.get<DistributionChannelsResponseDto>(`${environment.apiUrl}/distributions`);
  }

  public getCountSettings(): Observable<ICountsSettings> {
    return this.http.get(`${environment.apiUrl}/counts-settings`)
      .pipe(
        map((response: { data: ICountsSettingsServer, success: boolean }): ICountsSettings => {
          return this.tranformCountsSettings(response.data);
        }),
        tap((response: ICountsSettings): void => {
          this.countSettings.next(response);
          this._userCountSubject.next(response.navigation.userCount);
        })
      );
  }


  public subscribeToSyncUsersStream(): Subscription {
    let isCompleted: boolean = false;
    return interval(REFRESH_STATUS_POLLING_INTERVAL)
      .pipe(
        // Start with 0 to make the API call immediately on component initialization
        startWith(0),
        // Switch to new observable for API call on every tick
        switchMap(() => this.getUserSyncStatus()),
        // Only continue if the 'status' property is true
      )
      .subscribe({
        next: (res) => {
          if (res.data.status === 1) {
            this.syncInProgress$.next(true);
          } else if (res.data.status === 0) {
            if (this.syncInProgress$.value) {
              isCompleted = true;
              this.openCustomSnackBar('Users synchronisation is completed.', 'success');
              this.lastSynchronised$.next((moment().format(is12HoursFormat() ? LAST_SYNC_FORMAT_12HR : LAST_SYNC_FORMAT_24HR)).toString());
              this.updateUserCount();
            }
            this.syncInProgress$.next(false);
          }
        },
        error: (err) => {
          console.error(err);
          isCompleted = true;
          this.syncInProgress$.next(false);
          this.openCustomSnackBar('Users synchronisation couldn\'t be completed.', 'danger');
        },
        complete: () => {
          // if (!isCompleted) {
          //   this.syncInProgress$.next(false);
          //   this.openCustomSnackBar('Users synchronization couldn\'t be completed within the expected time. The process will continue in the background.', 'warning');
          // } else {
            // isCompleted = false;
          // }
        }
      });
  }

  private getUserSyncStatus(): Observable<IUserSyncStatus> {
    return this.http.get<IUserSyncStatus>(`${environment.apiUrl}/v2/sync-progress/actimo`);
  }

  private tranformCountsSettings(data: ICountsSettingsServer): ICountsSettings {
    return {
      navigation: {
        draftPostCount: data.navigation.draftPostCount,
        postsCount: data.navigation.postsCount,
        scheduledPostsCount: data.navigation.scheduled_posts_count,
        unreadNotificationCount: data.navigation.unreadNotificationCount,
        userCount: data.navigation.userCount,
        userGroupCount: data.navigation.userGroupCount,
      },
      settings: {
        companyLogo: data.settings.company_logo,
        currencySymbol: data.settings.currency_symbol,
      }
    }
  }

  private openCustomSnackBar(message, icon): void {
    this.snackBar.openFromComponent(IconSnackBarComponent, {
      data: {
        message: message,
        icon: icon,
      },
    });
  }
}
