import { Subject, combineLatest, map, takeUntil } from 'rxjs';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { TemplatePortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';

import { MatButton } from '@angular/material/button';
import { DashboardService } from '@sociuu/modules';

import { Notification } from '@sociuu/interfaces';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'notifications',
})
export class NotificationsComponent implements OnInit, OnDestroy {
  @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
  @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;
  @ViewChildren('description') private descriptions: QueryList<ElementRef>;
  @ViewChildren('readMoreBtn') private readMoreBtns: QueryList<ElementRef>;

  unreadCount: number = 0;

  systemNotifications: Notification[] = [];
  superAdminNotifications: Notification[] = [];

  notifications: Notification[] = [];
  numOfUnreadNotifications = 0;
  openedNotification = 0;

  private _overlayRef: OverlayRef;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    // private _notificationsService: NotificationsService,
    private _router: Router,
    private _overlay: Overlay,
    private domSanitizer: DomSanitizer,
    private _viewContainerRef: ViewContainerRef,
    private _dashboardService: DashboardService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.getNotifications();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();

    // Dispose the overlay
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Open the notifications panel
   */
  openPanel(): void {
    // Return if the notifications panel or its origin is not defined
    if (!this._notificationsPanel || !this._notificationsOrigin) {
      return;
    }

    // Create the overlay if it doesn't exist
    if (!this._overlayRef) {
      this._createOverlay();
    }

    // Attach the portal to the overlay
    this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
  }

  /**
   * Close the notifications panel
   */
  closePanel(): void {
    this._overlayRef.detach();
  }


  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  public trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  public sanitizeHtml(text: string): SafeStyle {
    return this.domSanitizer.bypassSecurityTrustHtml(text);
  }

  private getNotifications(): void {
    this._dashboardService
      .getNotificationList()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response) => {
        if (response.data.system) {
          response.data.system.forEach((item) => {
            this.notifications.push({
              post_id: item.post_id,
              client_notification_id: item.client_notification_id,
              read_by: item.read_by,
              created_at: item.created_at,
              created_by: item.created_by,
              description: this.sanitizeHtml(item.description) as string,
              status: item.status,
              title: item.title,
              notification_from: 'system',
              isReadMoreEnabled: false,
            });
          });
        }

        if (response.data.superadmin) {
          response.data.superadmin.forEach((item) => {
            this.notifications.push({
              post_id: item.post_id,
              client_notification_id: item.client_notification_id,
              read_by: item.read_by,
              created_at: item.created_at,
              created_by: item.created_by,
              description: this.sanitizeHtml(item.description) as string,
              status: item.status,
              title: item.title,
              notification_from: 'superadmin',
              isReadMoreEnabled: false,
            });
          });
        }

        this.notifications.map((notification) => {
          if (notification.status === 'unread') this.numOfUnreadNotifications++;
        });

        this.setReadMoreBtnVisibility();
      });
  }

  private setReadMoreBtnVisibility(): void {
    combineLatest([
      this.descriptions.changes,
      this.readMoreBtns.changes,
    ])
    .pipe(
      map(([descriptions, readMoreBtns]) => {
        return {
          descriptions: descriptions.toArray(),
          readMoreBtns: readMoreBtns.toArray(),
        }
      })
    )
    .subscribe(({descriptions, readMoreBtns}): void => {
      descriptions.forEach((element: ElementRef, index: number): void => {
        element.nativeElement.classList.remove('line-clamp-2');
        if (element.nativeElement.clientHeight <= 42) readMoreBtns[index].nativeElement.classList.add('hidden');
        else element.nativeElement.classList.add('line-clamp-2');
      });
    })
  }

  public readMoreHandler(event, notification: Notification): void {
    notification.isReadMoreEnabled = !notification.isReadMoreEnabled;
    event.target.previousSibling.classList.toggle('line-clamp-2');
  }

  public markNotificationAsRead(notification: Notification, type: string, index: number): void {
    this._dashboardService
      .markNotificationAsRead(notification.client_notification_id)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response) => {
        if (type === 'system') {
          this.systemNotifications.map((item) => {
            if (
              item.client_notification_id === notification.client_notification_id &&
              this.systemNotifications[index].status === 'unread'
            ) {
              this.systemNotifications[index].status = 'read';
              this.numOfUnreadNotifications--;
            }
          });
          this._router.navigateByUrl(`/posts/details/${notification.post_id}`);
        } else {
          this.superAdminNotifications.map((item) => {
            if (item.client_notification_id === notification.client_notification_id) {
              this.superAdminNotifications[index].status = 'read';
            }
          });
        }
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create the overlay
   */
  private _createOverlay(): void {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'fuse-backdrop-on-mobile',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
          },
        ]),
    });

    // Detach the overlay from the portal on backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.detach();
    });
  }
}
