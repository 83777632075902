import { environment } from '@utility/app.util';
import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AnalyticsProfilesResponseDto,
  CrawlDataResponseDto,
  CreditAvailability,
  DefaultResponseDto,
  DistributionChannelsResponseDto,
  NetworksResponseDto,
  ScheduledPostResponseDto,
  UrlResponseDto,
  WatermarkResponseDto,
} from '@sociuu/interfaces';

@Injectable({
  providedIn: 'root',
})
export class CreatePostService {

  constructor(private http: HttpClient) {}

  getAnalyticalProiles(): Observable<AnalyticsProfilesResponseDto> {
    return this.http.get<AnalyticsProfilesResponseDto>(`${environment.apiUrl}/client/getAnalyticsProfiles`);
  }

  getClientNetworks(): Observable<NetworksResponseDto> {
    return this.http.get<NetworksResponseDto>(`${environment.apiUrl}/client/getNetworks`);
  }

  crawler(link: string, linkText: string): Observable<CrawlDataResponseDto> {
    const postedData = {
      imageAmount: -1,
      link: link,
      text: linkText,
    };
    return this.http.post<CrawlDataResponseDto>(`${environment.apiUrl}/crawler/crawl`, postedData);
  }

  createPost(data): Observable<DefaultResponseDto> {
    return this.http.post<DefaultResponseDto>(`${environment.apiUrl}/v01/posts/create-new`, data);
  }

  saveAsDraft(data): Observable<DefaultResponseDto> {
    return this.http.post<DefaultResponseDto>(`${environment.apiUrl}/v01/posts/draft/create`, data);
  }

  getDraftPost(id: number): Observable<any> {
    return this.http.get<ScheduledPostResponseDto>(`${environment.apiUrl}/v01/posts/draft/${id}`);
  }

  getScheduledPost(id: number): Observable<ScheduledPostResponseDto> {
    return this.http.get<ScheduledPostResponseDto>(`${environment.apiUrl}/v01/posts/draft/${id}?type=scheduled`);
  }

  updateScheduledPost(data, id: number): Observable<DefaultResponseDto> {
    return this.http.put<DefaultResponseDto>(`${environment.apiUrl}/v01/posts/scheduled/update/${id}`, data);
  }

  updateDraftPost(data, id: number): Observable<DefaultResponseDto> {
    return this.http.put<DefaultResponseDto>(`${environment.apiUrl}/v01/posts/draft/update/${id}`, data);
  }

  setWatermarkOnImage(data): Observable<WatermarkResponseDto> {
    return this.http.post<WatermarkResponseDto>(`${environment.apiUrl}/watermark-text`, data);
  }

  uploadImageToS3(link: string): Observable<UrlResponseDto> {
    const data = { link: link };
    return this.http.post<UrlResponseDto>(`${environment.apiUrl}/posts/uploadUrlImage`, data);
  }

  getDistributions(): Observable<DistributionChannelsResponseDto> {
    return this.http.get<DistributionChannelsResponseDto>(`${environment.apiUrl}/distributions`);
  }

  checkCreditAvailability(data: CreditAvailability): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/credit/availability`, data);
  }

}
