import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

import { MatIconModule, MatIconRegistry } from '@angular/material/icon';

import { IIcons, ICONS } from './utils';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
  ],
})
export class RegisterIconsModule {
  private icons: IIcons[] = ICONS;

  constructor(
    private sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
  ) {
    this.registerIcons();
  }

  private registerIcons(): void {
    this.icons.forEach((icon: IIcons): void => {
      this.iconRegistry.addSvgIconLiteral(
        icon.name,
        this.sanitizer.bypassSecurityTrustHtml(icon.svg)
      );
    })
  }
}
