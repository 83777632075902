import {
  Translation,
  translocoConfig,
  TranslocoModule,
  TranslocoService,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  TRANSLOCO_TRANSPILER,
  FunctionalTranspiler,
} from '@ngneat/transloco';
import { environment } from '@utility/app.util';

import { APP_INITIALIZER, NgModule } from '@angular/core';

import { TranslocoHttpLoader } from 'app/core/transloco/transloco.http-loader';

import { SUPPORTED_LANGUAGES } from '@app/utility/app.util';
import { I18nPipe } from './i18n.pipe';
import { I18nService } from './i18n.service';

@NgModule({
  declarations: [I18nPipe],
  exports: [
    I18nPipe,
    TranslocoModule,
  ],
  providers: [
    {
      // Provide the default Transloco configuration
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: SUPPORTED_LANGUAGES,
        defaultLang: 'en-US',
        fallbackLang: 'en-US',
        reRenderOnLangChange: true,
        prodMode: environment.production,
        missingHandler: {
          allowEmpty: true,
          logMissingKey: true,
          useFallbackTranslation: true,
        }
      })
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    { provide: TRANSLOCO_TRANSPILER, useClass: FunctionalTranspiler },
    {
      // Preload the default language before the app starts to prevent empty/jumping content
      provide: APP_INITIALIZER,
      deps: [TranslocoService],
      useFactory: (translocoService: TranslocoService): any => (): Promise<Translation> => {
        const defaultLang = translocoService.getDefaultLang();
        translocoService.setActiveLang(defaultLang);
        return translocoService.load(defaultLang).toPromise();
      },
      multi: true
    }
  ]
})
export class TranslocoCoreModule {
  static forRoot() {
    return {
      providers: [I18nService],
      ngModule: TranslocoCoreModule,
    }
  }
}
