/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'basic',
    icon: 'heroicons_outline:template',
    link: '/dashboard',
    exactMatch: true,
  },
  {
    id: 'content-hub',
    title: 'Content Hub',
    type: 'basic',
    icon: 'heroicons_outline:collection',
    link: '/content-hub',
    exactMatch: true,
  },
  {
    id: 'create-post',
    title: 'Create Post',
    type: 'basic',
    icon: 'heroicons_outline:document-add',
    link: '/posts/create',
    exactMatch: true,
  },
  {
    id: 'create-native-content',
    title: 'Create Native Content',
    type: 'basic',
    icon: 'heroicons_outline:document-add',
    link: '/posts/native/create',
    exactMatch: true,
  },
  {
    id: 'posts',
    title: 'Posts',
    type: 'collapsable',
    icon: 'heroicons_outline:newspaper',
    exactMatch: true,
    children: [
      {
        id: 'posts.regular-posts',
        title: 'Regular Posts',
        type: 'basic',
        link: '/posts/regular',
        exactMatch: true,
      },
      {
        id: 'posts.webhook-posts',
        title: 'Webhook Posts',
        type: 'basic',
        disabled: false,
        link: '/posts/webhook',
        exactMatch: true,
      },
      {
        id: 'posts.sociuuhub-posts',
        title: 'Sociuu Hub',
        type: 'basic',
        link: '/posts/sociuuhub',
        exactMatch: true,
      },
    ],
  },
  {
    id: 'scheduled-posts',
    title: 'Scheduled Posts',
    type: 'basic',
    icon: 'mat_outline:access_time',
    link: '/posts/schedule',
    exactMatch: true,
  },
  {
    id: 'draft-posts',
    title: 'Draft Posts',
    type: 'basic',
    icon: 'heroicons_outline:document-text',
    link: '/posts/my-drafts',
    exactMatch: true,
  },
  {
    id: 'user-managment',
    title: 'Users Management',
    type: 'collapsable',
    icon: 'feather:users',
    exactMatch: true,
    link: '/users',
    children: [
      {
        id: 'users',
        title: 'Users',
        type: 'collapsable',
        exactMatch: true,
        children: [
          {
            id: 'users.all-users',
            title: 'All Users',
            type: 'basic',
            link: '/users',
            badge: {
              title: '27',
              classes: 'px-2 bg-pink-600 text-white rounded-full',
            },
            exactMatch: true,
          },
          {
            id: 'users.import',
            title: 'Import Users',
            type: 'basic',
            link: '/users/import',
            exactMatch: true,
            disabled: true,
          },
          {
            id: 'users.sync-users',
            title: 'Sync Users',
            type: 'basic',
            link: '/sync-users',
            exactMatch: true,
          },
        ],
      },
      {
        id: 'user-managment.user-groups',
        title: 'User Groups',
        type: 'basic',
        link: '/user-groups',
        exactMatch: true,
      },
      {
        id: 'user-managment.departments',
        title: 'Departments',
        type: 'basic',
        link: '/departments',
        exactMatch: true,
      },
    ],
  },
  {
    id: 'tags',
    title: 'Tags',
    type: 'basic',
    icon: 'heroicons_outline:tag',
    link: '/tags',
    exactMatch: true,
  },
  {
    id: 'scoreboard',
    title: 'Scoreboard',
    type: 'basic',
    icon: 'heroicons_outline:clipboard-list',
    link: '/scoreboard',
    exactMatch: true,
  },
  {
    id: 'i-s',
    title: 'Insights & Statistics',
    type: 'collapsable',
    icon: 'insights',
    exactMatch: true,
    children: [
      {
        id: 'i-s.overview',
        title: 'Overview',
        type: 'basic',
        link: '/insights',
        exactMatch: true,
      },
      {
        id: 'i-s.benchmark',
        title: 'Benchmark',
        type: 'basic',
        link: '/insights/benchmark',
        exactMatch: true,
      },
      {
        id: 'i-s.engagement',
        title: 'Engagement',
        type: 'basic',
        link: '/insights/engagement',
        exactMatch: true,
      },
      {
        id: 'i-s.post-effectiveness',
        title: 'Post Effectiveness',
        type: 'basic',
        link: '/insights/post-effectiveness',
        exactMatch: true,
      },
      {
        id: 'i-s.usergroup-stats',
        title: 'Usergroup Stats',
        type: 'basic',
        link: '/insights/usergroup-stats',
        exactMatch: true,
      },
    ],
  },
  {
    id: 'settings',
    title: 'Settings',
    type: 'collapsable',
    icon: 'feather:settings',
    exactMatch: true,
    children: [
      {
        id: 'settings.general',
        title: 'General',
        type: 'basic',
        link: '/settings',
        exactMatch: true,
      },
      {
        id: 'settings.email',
        title: 'Welcome Email',
        type: 'basic',
        link: '/email/template',
        exactMatch: true,
      },
      {
        id: 'settings.media-library',
        title: 'Default Image Library',
        type: 'basic',
        link: '/media-library',
        exactMatch: true,
      },
      // {
      //   id: 'settings.roles-and-permissions',
      //   title: 'Roles & Permissions',
      //   type: 'basic',
      //   disabled: true,
      //   link: '/security/roles',
      //   exactMatch: true,
      // },
      {
        id: 'settings.roles-and-permission-v2',
        title: 'Roles & Permissions',
        type: 'basic',
        disabled: true,
        link: '/settings/security/roles',
        exactMatch: true,
      },
    ],
  },
];
export const compactNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
export const horizontalNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
