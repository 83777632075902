import isNil from 'lodash/isNil';
import { environment } from '@utility/app.util';
import { Observable, Subject, of, throwError } from 'rxjs';
import { catchError, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { AppService } from '@app/app.service';

import {
  RolesDto,
  Usergroup,
  CsvUploadDto,
  CreateUserDto,
  UsersResponseDto,
  DryRunResponseDto,
  CreateUsergroupDto,
  DefaultResponseDto,
  UpdateUsergroupDto,
  TagsListResponseDto,
  UsergroupsResponseDto,
  UpdateUsergroupDtoServer,
  CreateUsergroupDtoServer,
  AddRemoveUsersFromUsergroupsDto,
} from '@sociuu/interfaces';
import {
  IUser,
  IUserV2,
  IMoveMerge,
  IUserGroup,
  IUserServer,
  IUserGroups,
  IUserPayload,
  IUsersParams,
  IUserGroupsData,
  IMoveMergeServer,
  IUserGroupsServer,
  IMoveMergePayload,
  IUserGroupsDataServer,
  IRejectSignupUsersPayload,
  IApproveSignupUsersPayload,
  IFetchUsergroupPayload,
} from '@lib/users.interface';
import { ITag, ITagServer } from '@lib/tag.interface';
import { UserInfoDto } from '@sociuu/lib/dto/user-info.dto'
import { IDepartmentResponse } from '@lib/departments.interface';
import { ICountry, ICountryDataServer } from '@lib/country.interface';
import { ISendEmail, ISendEmailResponse } from '@lib/welcome-email.interface';
import { IPagination, IPaginationServer, transformPagination } from '@app/utility/app.util';
import { ISignupStatusPayload, ISignupStatus, ISignupStatusServer } from '@lib/self-signup.interface';

import { CustomEncoder } from '@sociuu/utility';

export interface IChangeRoleRequest {
  role: number;
  user_id: number;
}

export interface IServerUpdateUser {
  data: UserInfoDto;
  messages: string[];
  success: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  pagination = 10;
  sort_order = 'desc';
  part = 0;
  interval = 'allTime';
  date_from = '';
  date_to = '';
  usergroups = true;
  sort_by = 'created_at';

  private cancelGetUserRequest$: Subject<void> = new Subject<void>();
  private cancelGetUserGroupRequest$: Subject<void> = new Subject<void>();

  constructor(
    private http: HttpClient,
    private appService: AppService,
  ) {}

  public cancelPreviousGetUserRequest() {
    this.cancelGetUserRequest$.next();
  }

  public cancelPreviousGetUserGroupRequest() {
    this.cancelGetUserGroupRequest$.next();
  }

  public getUsers(payload: IUserPayload): Observable<{ data: IUserV2[], meta: IPagination }> {
    const {
      page,
      search,
      content_id,
      targetAudienceAll,
      is_social_connected,
      includeUserIds,
      includeUsergroupIds,
      includeDepartmentIds,
      excludeUserIds,
      excludeUsergroupIds,
      excludeDepartmentIds,
    } = payload;

    let params = new HttpParams({ encoder: new CustomEncoder() });

    params = params.append('page', `${page}`);
    params = params.append('limit', `${this.pagination}`);

    if (search) params = params.append('search', `${search}`);
    if (content_id) params = params.append('filter[content_id]', `${content_id}`);
    if (is_social_connected) params = params.append('filter[is_social_connected]', '1');
    if (!isNil(targetAudienceAll)) {
      params = params.append('fields[users]', 'id,first_name,last_name');
      params = params.append('filter[target_audience_all]', targetAudienceAll ? '1' : '0');
      if (excludeUserIds) params = params.append('filter[exclude][user_ids]', excludeUserIds);
      if (excludeUsergroupIds) params = params.append('filter[exclude][usergroup_ids]', excludeUsergroupIds);
      if (excludeDepartmentIds) params = params.append('filter[exclude][department_ids]', excludeDepartmentIds);

      if (!targetAudienceAll) {
        if (includeUserIds) params = params.append('filter[include][user_ids]', includeUserIds);
        if (includeUsergroupIds) params = params.append('filter[include][usergroup_ids]', includeUsergroupIds);
        if (includeDepartmentIds) params = params.append('filter[include][department_ids]', includeDepartmentIds);
      }
    }

    return this.http.get<{ data: IUser[], meta: IPaginationServer }>(`${environment.apiUrl}/v2/users`, { params })
      .pipe(
        takeUntil(this.cancelGetUserRequest$),
        map((response: { data: IUser[], meta: IPaginationServer }): { data: IUserV2[], meta: IPagination } => {
          return {
            meta: transformPagination(response.meta),
            data: response.data.map((user: IUser): IUserV2 => this.transformUserV2(user)),
          }
        })
      );
  }

  private transformUserV2(response: IUser): IUserV2 {
    return {
      id: response.id,
      lastName: response.last_name,
      firstName: response.first_name,
      title: `${response.first_name} ${response.last_name}`,
    }
  }

  getAllUsers(data?: IUsersParams): Observable<UsersResponseDto> {
    const { page, key, welcome_email = -1, show_external = -1, usergroups = [], state } = data;
    let params = new HttpParams({ encoder: new CustomEncoder() });
    params = params.append('page', `${page}`);
    params = params.append('sort_by', `${this.sort_by}`);
    params = params.append('pagination', `${this.pagination}`);
    params = params.append('sort_order', `${this.sort_order}`);


    if (key) params = params.append('key', `${key}`);
    if (state) params = params.append('state', `${state}`);
    if (this.date_to) params = params.append('date_to', `${this.date_to}`);
    if (this.interval) params = params.append('interval', `${this.interval}`);
    if (this.date_from) params = params.append('date_from', `${this.date_from}`);
    if (usergroups.length) params = params.append('usergroups', `${usergroups}`);
    if (show_external !== -1) params = params.append('show_external', `${show_external}`);
    if (welcome_email !== -1) params = params.append('welcome_email', `${welcome_email}`);

    return of({})
    .pipe(
      switchMap(() => {
        return this.http.get<UsersResponseDto>(`${environment.apiUrl}/v01/users/list`, {params: params})
        .pipe(
          takeUntil(this.cancelGetUserRequest$),
          catchError(error => {
            return throwError(error);
          })
        );
      })
    );
  }

  exportUserStats(fileType: string, includeStats: boolean = false, usergroups: number[]) {
    let params = new HttpParams();
    params = params.append('stats', `${includeStats}`);
    params = params.append('usergroups', `${this.usergroups}`);
    params = params.append('interval', `${this.interval}`);

    if (this.interval === 'custom') {
      params = params.append('date_from', `${this.date_from}`);
      params = params.append('date_to', `${this.date_to}`);
    }

    if (usergroups.length) {
      params = params.append('usergroupIds', `${usergroups}`);
    }

    return this.http.get<any>(`${environment.apiUrl}/users/export?fileType=${fileType}`, { params: params });
  }

  getUsersForRecipients(distributionId?: number): Observable<UsersResponseDto> {
    let params = new HttpParams();
    if (distributionId) {
      params = params.append('distributionId', distributionId);
    }
    return this.http.get<UsersResponseDto>(`${environment.apiUrl}/v01/users/list?pagination=999999`, { params: params });
  }

  public createUser(data: CreateUserDto): Observable<IUser> {
    return this.http.post<{data: IUserServer}>(`${environment.apiUrl}/v2/users`, data)
      .pipe(
        tap(() => this.appService.updateUserCount()),
        map((response: { data: IUserServer }): IUser => this.transformUser(response.data)),
      );
  }

  private transformUser(user: IUserServer): IUser {
    return {
      ...user,
      department: [{
        ...user.department,
        title: user.department.name,
      }],
      is_active: user.is_active,
      usergroups_count: user.usergroups?.length,
    }
  }

  deleteUser(user_id: number): Observable<DefaultResponseDto> {
    return this.http.delete<DefaultResponseDto>(`${environment.apiUrl}/users/` + user_id)
    .pipe(tap(() => this.appService.updateUserCount()));
  }

  subscribeUser(userIds: number[]): Observable<DefaultResponseDto> {
    const data = { ids: userIds };
    return this.http.put<DefaultResponseDto>(`${environment.apiUrl}/users/subscribe`, data);
  }

  deleteUserByIds(userIds): Observable<DefaultResponseDto> {
    const data = { ids: userIds };
    return this.http.post<DefaultResponseDto>(`${environment.apiUrl}/users/delete-multiple`, data)
    .pipe(tap(() => this.appService.updateUserCount()));
  }

  updateUserAccount(data: CreateUserDto): Observable<IServerUpdateUser> {
    return this.http.put<IServerUpdateUser>(`${environment.apiUrl}/users/update`, data);
  }

  public getDepartments(): Observable<IDepartmentResponse> {
    return this.http.get<IDepartmentResponse>(`${environment.apiUrl}/departments/list?with_pagination=no`);
  }

  public getNoAuthDepartments(): Observable<IDepartmentResponse> {
    return this.http.get<IDepartmentResponse>(`${environment.sociuuhubApiUrl}/departments/list?with_pagination=no`);
  }

  changeRole(data): Observable<RolesDto> {
    return this.http.post<RolesDto>(`${environment.apiUrl}/users/roles`, data);
  }

  /*
   * USERGROUPS
   */
  getAllUsergroups(data?: { page: number; key?: string, show_external?: boolean }): Observable<any> {
    const { page, key, show_external = -1 } = data;
    let params = new HttpParams();
    params = params.append('page', `${page}`);
    params = params.append('sort_by', `${this.sort_by}`);
    params = params.append('pagination', `${this.pagination}`);
    params = params.append('sort_order', `${this.sort_order}`);

    if (key) params = params.append('key', `${key}`);

    if (show_external !== -1) params = params.append('show_external', `${show_external}`);

    return of({})
      .pipe(
        switchMap(() => {
          return this.http.get<UsergroupsResponseDto>(`${environment.apiUrl}/usergroups/list?`, { params: params })
          .pipe(
            takeUntil(this.cancelGetUserGroupRequest$),
            catchError(error => {
              return of(error);
            }),
            map((response: UsergroupsResponseDto) => ({
              ...response,
              data: {
                ...response.data,
                data: response.data.data.map((userGroup: Usergroup) => ({
                  ...userGroup,
                  tags: userGroup.tags.map((tag): ITag => ({
                    id: tag.id,
                    title: tag.tag_name,
                    tagName: tag.tag_name,
                    clientId: tag.client_id,
                    createdAt: tag.created_at,
                  })),

                  countries: userGroup.countries.map((country: ICountryDataServer): ICountry => ({
                    id: country.code,
                    code: country.code,
                    name: country.name,
                    title: country.name,
                    createdAt: country.created_at,
                    dialingCode: country.dialing_code,
                  }))
                })),
              },
            }))
          );
        })
      );
  }

  getUsergroupsByPosts(data?: { page: number; pid?: string }): Observable<UsergroupsResponseDto> {
    const { page, pid } = data;
    let params = new HttpParams();
    params = params.append('page', `${page}`);
    params = params.append('pid', `${pid}`);

    return this.http.get<UsergroupsResponseDto>(`${environment.apiUrl}/posts/usergroups/list?`, { params: params });
  }

  getUsergroupsForRecipients(distributionId?: number): Observable<UsergroupsResponseDto> {
    let params = new HttpParams();
    if (distributionId) {
      params = params.append('distributionId', distributionId);
    }
    return this.http.get<UsergroupsResponseDto>(`${environment.apiUrl}/usergroups/list?pagination=999999`, { params: params });
  }

  public getUsergroupUsersCount(data: number[]): Observable<number> {
    const payload = { usergroup_ids: data };
    return this.http.post<{ data: { user_count: number } }>(`${environment.apiUrl}/v2/usergroups/users/count`, payload)
      .pipe(
        map((response: { data: { user_count: number } }): number => response.data.user_count)
      );
  }

  public mergeMoveUsergroups(payload: IMoveMergePayload): Observable<IMoveMerge> {
    return this.http.post<{ data: IMoveMergeServer }>(`${environment.apiUrl}/v2/usergroups/merge`, payload)
      .pipe(
        map((response: { data: IMoveMergeServer }): IMoveMerge => this.transformMergeMoveUsergroups(response.data))
      );
  }

  private transformMergeMoveUsergroups(response: IMoveMergeServer): IMoveMerge {
    return {
      usersMovedCountActive: response.users_moved_count_active,
      usersMovedCountDelete: response.users_moved_count_deleted,
      usersMovedCountTotal: response.users_moved_count_total,
      sourceGroupsCount: response.source_groups_count,
      deletedGroupsCount: response.deleted_groups_count,
      deleteSourceAfterMove: response.delete_source_after_move,
    }
  }

  public getUsergroupsV2(payload: IFetchUsergroupPayload): Observable<IUserGroups> {
    const {
      sort,
      title,
      countries,
      countryCode,
      showExternal,
      pagination = 10,
      currentPage = 1,
      isNoAuthEnabled = false,
      enableRestrictionMode,
    } = payload;

    const apiURL: string = isNoAuthEnabled ?
      `${environment.sociuuhubApiUrl}/usergroups/list` :
      `${environment.apiUrl}/v2/usergroups`
    ;

    let params = new HttpParams();
    params = params.append('pagination', 10);
    params = params.append('page', `${currentPage}`);

    if (sort) params = params.append('sort', sort);
    if (title) params = params.append('filter[title]', title);
    if (pagination) params = params.append('pagination', pagination);
    if (countryCode) params = params.append('country_code', countryCode);
    if (countries) params = params.append('countries', countries.toString());
    if (enableRestrictionMode) params = params.append('with_usergroup_restriction', enableRestrictionMode);
    if (!isNil(showExternal) && showExternal !== -1) params = params.append('show_external', `${showExternal}`);

    return this.http.get<IUserGroupsServer>(`${apiURL}`, { params: params })
      .pipe(
        takeUntil(this.cancelGetUserGroupRequest$),
        map((response: IUserGroupsServer): IUserGroups => this.transformUserGroups(response, isNoAuthEnabled)),
      );
  }

  private transformUserGroups(response: IUserGroupsServer, isNoAuthEnabled: boolean): IUserGroups {
    return {
      to: response.to ?? response?.meta?.to ?? 1,
      from: response.from ?? response?.meta?.from ?? 1,
      total: response.total ?? response.meta.total,
      perPage: response.per_page ?? response.meta.per_page,
      lastPage: response.last_page ?? response.meta.last_page,
      currentPage: response.current_page ?? response.meta.current_page,
      data: this.transformUserGroupsData(response.data, isNoAuthEnabled),
    };
  }

  private transformUserGroupsData(response: IUserGroupsDataServer[], isNoAuthEnabled: boolean): IUserGroupsData[] {
    return response?.map((userGroup: IUserGroupsDataServer): IUserGroupsData => ({
      id: userGroup.id,
      title: userGroup.title,
      ...(!isNoAuthEnabled && {
        slug: userGroup?.slug,
        clientId: userGroup?.client_id,
        createdAt: userGroup?.created_at,
        deletedAt: userGroup?.deleted_at,
        isEditable: userGroup?.is_editable,
        isExternal: userGroup?.is_external,
        tags: this.transformTags(userGroup?.tags),
        countries: this.transformCountries(userGroup?.countries),
        ...(!isNil(userGroup?.post_count) && { postCount: userGroup.post_count }),
        ...(!isNil(userGroup?.users_count) && { usersCount: userGroup.users_count }),
      }),
    }));
  }

  private transformTags(tags: ITagServer[]): ITag[] {
    return tags?.map((tag: ITagServer): ITag => ({
      id: tag.id,
      title: tag.tag_name,
      tagName: tag.tag_name,
      clientId: tag.client_id,
      createdAt: tag.created_at,
    }));
  }

  private transformCountries(countries: ICountryDataServer[]): ICountry[] {
    return countries?.map((country: ICountryDataServer): ICountry => ({
      id: country.code,
      code: country.code,
      name: country.name,
      title: country.name,
      createdAt: country.created_at,
      dialingCode: country.dialing_code,
    }));
  }

  getAdmins(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/users/admins`);
  }

  getPostUsers(post_id: number, sort_by: string, sort_order: string, page: number, page_size: number): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/v01/users/list/byPost/${post_id}?page=${page}&pagination=${page_size}&sort_by=${sort_by}&sort_order=${sort_order}`
    );
  }

  getContentUserListWithStats(post_id: number): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/v2/content/${post_id}/users`
    );
  }

  searchUsersByKey(pagination: number, key: string, distributionId?: number): Observable<UsersResponseDto> {
    let params = new HttpParams();
    params = params.append('pagination', pagination);
    params = params.append('key', `${key}`);

    if (distributionId) {
      params = params.append('distributionId', distributionId);
    }

    return this.http
      .get<UsersResponseDto>(`${environment.apiUrl}/v01/users/list`, {
        params: params,
      })
      .pipe(shareReplay(1));
  }

  getUsersFromUsergroups(usergroupIds: string, distributionId?: number) {
    let params = new HttpParams();
    params = params.append('usergroups', `${usergroupIds}`);

    if (distributionId) {
      params = params.append('distributionId', distributionId);
    }
    return this.http.get<UsersResponseDto>(`${environment.apiUrl}/v01/users/list?pagination=999999`, { params: params });
  }

  createUsergroup(data): Observable<CreateUsergroupDto> {
    return this.http.post<CreateUsergroupDtoServer>(`${environment.apiUrl}/usergroups/create`, data)
    .pipe(
      map((response) => ({
        ...response,
        ...(response?.data && {
          data: {
            ...response.data,
            countries: response.data?.countries.map((country: ICountryDataServer): ICountry => ({
              id: country.code,
              code: country.code,
              name: country.name,
              title: country.name,
              createdAt: country.created_at,
              dialingCode: country.dialing_code,
            })),
          },
        }),
      })),
    );
  }

  deleteUserGroup(id: number): Observable<DefaultResponseDto> {
    return this.http.delete<DefaultResponseDto>(`${environment.apiUrl}/usergroups/${id}`);
  }

  deleteUserGroupsByIds(groupIds): Observable<DefaultResponseDto> {
    const data = { ids: groupIds };
    return this.http.post<DefaultResponseDto>(`${environment.apiUrl}/usergroups/bulk-actions/delete`, data);
  }

  updateUsergroup(editPayload): Observable<UpdateUsergroupDto> {
    return this.http.put<UpdateUsergroupDtoServer>(`${environment.apiUrl}/usergroups/update`, editPayload)
      .pipe(
        map((response) => ({
          ...response,
          messages: response.messages[0],
          data: {
            ...response.data,
            countries: response.data.countries.map((country: ICountryDataServer): ICountry => ({
              id: country.code,
              code: country.code,
              name: country.name,
              title: country.name,
              createdAt: country.created_at,
              dialingCode: country.dialing_code,
            })),
          },
        })),
      );
  }

  usersInGroup(id: number, type: string, data?: { page: number; key?: string }) {
    const { page, key } = data;

    let params = new HttpParams();
    params = params.append('page', `${page}`);

    if (key) {
      params = params.append('key', `${key}`);
    }

    return this.http.get<UsersResponseDto>(`${environment.apiUrl}/usergroups/getusers/${id}/${type}`, { params: params });
  }

  public attachTagsToUsergroup(body): Observable<TagsListResponseDto> {
    return this.http.post<TagsListResponseDto>(`${environment.apiUrl}/tags/attach-to-usergroup`, body);
  }

  public detachTagsFromUsergroup(body): Observable<TagsListResponseDto> {
    return this.http.post<TagsListResponseDto>(`${environment.apiUrl}/tags/detach-from-usergroup`, body);
  }

  addRemoveUsers(user_ids: number[], group_id: string | number, type: 'add' | 'remove'): Observable<AddRemoveUsersFromUsergroupsDto> {
    const body = {
      user_ids,
      group_id,
      type,
    };
    return this.http.post<AddRemoveUsersFromUsergroupsDto>(`${environment.apiUrl}/usergroups/users/add-or-remove`, body);
  }

  public assignUsergroup(user_id: number, usergroup_ids: number[], allow_empty: boolean): Observable<IUserGroup[]> {
    const body = { user_id, usergroup_ids, allow_empty };
    return this.http.post<{ data: IUserGroup[] }>(`${environment.apiUrl}/v2/usergroups/users/assign`, body)
      .pipe(
        takeUntil(this.cancelGetUserGroupRequest$),
        map((response: { data: IUserGroupsDataServer[] }): IUserGroupsDataServer[] =>  response.data)
      );
  }

  public approveSignupUsers(payload: IApproveSignupUsersPayload): Observable<any> {
    return this.http.post<{ data: IUserGroup[] }>(`${environment.apiUrl}/v2/users/approve-signup`, payload);
  }

  public rejectSignupUsers(payload: IRejectSignupUsersPayload): Observable<any> {
    return this.http.post<{ data: IUserGroup[] }>(`${environment.apiUrl}/v2/users/reject-signup`, payload);
  }

  uploadLCSVFile(fileToUpload: File): Observable<CsvUploadDto> {
    const formData: FormData = new FormData();
    formData.append('csv', fileToUpload);
    return this.http.post<CsvUploadDto>(`${environment.apiUrl}/upload/users/csv`, formData);
  }

  public acceptOptInUser(payload: ISendEmail) {
    return this.http.post<ISendEmailResponse>(`${environment.apiUrl}/v2/users/admin-opt-in-action`, payload);
  }

  public getSignupStatus(payload: ISignupStatusPayload): Observable<ISignupStatus> {
    return this.http.post<{ data: ISignupStatusServer }>(`${environment.apiUrl}/v2/user/signup-status`, payload)
      .pipe(
        map((response: { data: ISignupStatusServer }): ISignupStatusServer => response.data),
        map((response: ISignupStatusServer): ISignupStatus => ({
          action: response.action,
          userId: response?.user_id,
          message: response?.message,
          usergroups: response.usergroups,
          redirectTo: response.redirect_to,
          currentUserState: response?.current_user_state,
          department: {
            id: response.department?.id,
            name: response.department?.name,
            title: response.department?.name,
          },
        })),
      );
  }

  public updateUser(data: any): Observable<any> {
    const { userId, departmentId } = data;
    let params = new HttpParams();
    params = params.append('user_id', `${userId}`);
    params = params.append('department_id', `${departmentId}`); // $allowedProperties = ['department_id', 'first_name', 'last_name', 'title_1', 'title_2'];

    return this.http.patch<{ data: IUserServer }>(`${environment.apiUrl}/v2/users`, params);
  }

  performImportDryRun(
    filename,
    col_order = [],
    duplicate = '',
    ignore_list = [],
    confirmation = '',
    encode_type = 'utf-8',
  ): Observable<DryRunResponseDto> {
    const formData = {
      csv: filename,
      duplicate: duplicate,
      col_order: col_order,
      ignore_list: ignore_list,
      encode_type: encode_type,
      confirmation: confirmation,
    };

    return this.http.post<DryRunResponseDto>(environment.apiUrl + '/dry-run', formData);
  }

  performImport(
    filename,
    col_order = [],
    duplicate = '',
    ignore_list = [],
    encode_type = 'utf-8',
    confirmation = '',
    welcomeEmail,
  ): Observable<any> {
    const formData = {
      csv: filename,
      duplicate: duplicate,
      col_order: col_order,
      ignore_list: ignore_list,
      encode_type: encode_type,
      confirmation: confirmation,
      welcome_email: welcomeEmail ? 1 : 0,
    };

    return this.http.post<any>(environment.apiUrl + '/perform-import', formData);
  }
}
