import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DeleteConfirmDialogBoxComponent } from './components/delete-confirm-dialog-box/delete-confirm-dialog-box.component';
import { SelectUsersComponent } from './components/select-users/select-users.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NumericInputComponent } from './components/numeric-input/numeric-input.component';
import { CustomRangePanelComponent } from './components/custom-datepicker/custom-range-panel.component';
import { CustomDatepickerComponent } from './components/custom-datepicker/custom-datepicker.component';
import { MatDatepickerTimeHeaderModule } from './components/datepicker-with-time/datepicker-with-time.module';

@NgModule({
  declarations: [
    SelectUsersComponent,
    DeleteConfirmDialogBoxComponent,
    CustomRangePanelComponent,
    CustomDatepickerComponent,
    NumericInputComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatDividerModule,
    MatChipsModule,
    MatCardModule,
    ScrollingModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDatepickerTimeHeaderModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SelectUsersComponent,
    DeleteConfirmDialogBoxComponent,
    NumericInputComponent,
  ]
})
export class SharedModule {
}
