import { Subject } from 'rxjs';

import { Directive, OnDestroy } from '@angular/core';

@Directive()
export class CoreComponent implements OnDestroy{
  public destroyed$ = new Subject<void>();

  constructor() { }

  public ngOnDestroy() : void{
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
