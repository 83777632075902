import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@utility/app.util';
import { DefaultResponseDto, ScheduledPostsResponseDto } from '@sociuu/interfaces';

@Injectable({
  providedIn: 'root'
})
export class DraftPostsService {

  pagination = 10;
  sort_by = 'created_at';
  sort_order = 'DESC';

  constructor(private http: HttpClient) {
  }

  getDraftPostsList(page: number, filters: any): Observable<ScheduledPostsResponseDto> {
    let str = '';
    filters.sort_order = this.sort_order;
    filters.sort_by = this.sort_by;
    for (const p in filters) {
      if (filters.hasOwnProperty(p)) {
        str += p + '=' + filters[p] + '&';
      }
    }
    return this.http.get<ScheduledPostsResponseDto>(`${environment.apiUrl}/v01/posts/draft/list?page=${page}&pagination=${this.pagination}&${str}`);
  }

  deleteMultiple(ids: number[]): Observable<DefaultResponseDto> {
    const data = { 'ids': ids };
    return this.http.post<DefaultResponseDto>(`${environment.apiUrl}/v01/posts/draft/delete`, data);
  }
}
