import { Observable, of } from 'rxjs';
import { Translation, TranslocoLoader } from '@ngneat/transloco';

import { Injectable } from '@angular/core';

import enUS from '@assets/i18n/en-US';
import daDK from '@assets/i18n/da-DK';
import deDE from '@assets/i18n/de-DE';
import esES from '@assets/i18n/es-ES';
import nlNL from '@assets/i18n/nl-NL';
import trTR from '@assets/i18n/tr-TR';

export enum Locale {
  DUTCH = "nl-NL",
  GERMAN = "de-DE",
  DANISH = "da-DK",
  FRENCH = "fr-FR",
  SPANISH = "es-ES",
  FINNISH = "fi-FI",
  SWEDISH = "sv-SE",
  TURKICH = "tr-TR",
  NORWEGIAN = "nb-NO",
  ENGLISH_UK = "en-uk",
  ENGLISH_USA = "en-US",
}

@Injectable({
  providedIn: 'root',
})
export class TranslocoHttpLoader implements TranslocoLoader {

  constructor() {}
  getTranslation(lang: string): Observable<Translation> {
    if (lang === Locale.DUTCH) return of(nlNL);
    if (lang === Locale.DANISH) return of(daDK);
    if (lang === Locale.GERMAN) return of(deDE);
    if (lang === Locale.SPANISH) return of(esES);
    if (lang === Locale.TURKICH) return of(trTR);
    if (lang === Locale.ENGLISH_UK) return of(enUS);
    if (lang === Locale.ENGLISH_USA) return of(enUS);
  }
}
