import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FrequencyRatioComponent } from './frequency-ratio.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgApexchartsModule } from 'ng-apexcharts';

@NgModule({
  declarations: [FrequencyRatioComponent],
  exports: [FrequencyRatioComponent],
  imports: [
    CommonModule,
    MatDividerModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    NgApexchartsModule
  ],
})
export class FrequencyRatioModule {}
