import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'app/app.module';

import { environment } from '@utility/app.util';
import { sentryInit } from '@app/utility/sentry.utils';

sentryInit();

if ( environment.production )
{
    enableProdMode();
    window.console.log = () => {};
}

platformBrowserDynamic().bootstrapModule(AppModule)
                        .catch(err => console.error(err));
