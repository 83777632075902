export default {
  // Magic Login Page
  "Request Magic Login": "Fordern Sie Magic Login an",
  "To make life easy we've skipped lengthy and difficult passwords. Enter your email and check your inbox for an email with a login link.": "Um dir das Leben leichter zu machen, haben wir auf lange und schwierige Passwörter verzichtet. Gib deine E-Mail-Adresse ein und wir senden dir einen Zugangslink per E-Mail.",
  "Email Address": "Email Adresse",
  "Email is required.": "Eine E-Mail ist erforderlich.",
  "Invalid Email": "E-Mail ist ungültig.",
  "Send magic link": "Link anfordern",
  "Welcome to your<br/>Content Hub": "Willkommen in deinem</br>Content Hub",
  "This is your personal Content Hub, where you will find our latest news, social media posts, and job listings that you can share with your network. Enter your email address to gain access.": "Hier findest du vorbereitete Inhalte, dein persönliches Dashboard und Scoreboards. Außerdem kannst du Themen abonnieren oder abbestellen.",

  // Magic Verify Page
  "Verifying user!": "Benutzer wird verifiziert!",

  // Signup Page
  "Signup": "",
  "Signin": "",
  "Sign In": "",
  "Already have an account?": "",
  "First name": "",
  "Last name": "",
  "Email address": "",
  "Phone": "",
  "Department": "",
  "Department is required": "",
  "I agree to the": "",
  "Welcome to": "",
  "Create your account": "",
  "Invalid phone number": "",
  "Allowed domains i.e;": "",
  "Email address or Phone number is required": "",
  "Invalid domain": "",
  "Please enter a valid email address": "",
  "Last name is required": "",
  "Whitespaces are not allowed": "",
  "First name is required": "",

  // Welcome modal
  "Hi {{ userName }}": "Hallo {{ userName }}",
  "Welcome to our social media hub. Take part in sharing all our great stories, news, job posts and more.": "Willkommen in unserem Social Media Hub. Beteilige dich an der Verbreitung all unserer großartigen Geschichten, Neuigkeiten, Stellenanzeigen und mehr.",
  "Every share counts.": "Jeder Beitrag zählt.",
  "Get started right here": "Hier starten",
};
