import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CoreComponent } from '@sociuu/core';
import { UsersService } from '@admin/users/users.service';
import { User } from 'libs/interfaces/src/lib/models/user';
import { Usergroup } from 'libs/interfaces/src/lib/models/usergroup';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sociuu-select-users',
  templateUrl: './select-users.component.html',
  styleUrls: ['./select-users.component.scss'],
})
export class SelectUsersComponent extends CoreComponent implements OnInit, OnChanges {
  @Input() users: User[];
  @Input() usergroups: Usergroup[];
  @Input() postUsers: User[];
  @Input() currentStep: number;
  @Input() parentForm: FormGroup;
  @Input() grandParentForm: FormGroup;
  @Input() pagination = 10;
  @Input() selectedUsers: User[];
  @Input() selectedUserGroups: Usergroup[];
  @Input() distributionTypeId: number;
  form: FormGroup;
  distributionType = null;

  constructor(private fb: FormBuilder, private usersService: UsersService) {
    super();
    this.form = this.fb.group({
      users: this.fb.array([], [Validators.required]),
      usergroups: this.fb.array([]),
      search: [''],
    });
  }

  ngOnInit(): void {
    this.parentForm.setControl('select_users', this.form);
    this.searchUserbyKeyword();
  }

  ngOnChanges(changes: any) {
    if (changes?.currentStep?.currentValue === 3) {
      this.getUsergroups(this.distributionTypeId);
      if (this.usergroups.length) this.getUsersFromUsergroups();
      else this.getUsers(this.distributionTypeId);
    }

    if (changes.selectedUsers) {
      const users = this.form.get('users') as FormArray;
      users.clear();
      this.selectedUsers?.forEach((user) => {
        users.push(new FormControl(user));
      });
    }

    if (changes.selectedUserGroups) {
      const userGroups = this.form.get('usergroups') as FormArray;
      userGroups.clear();
      this.selectedUserGroups?.forEach((userGroup) => {
        userGroups.push(new FormControl(userGroup));
      });
    }
  }

  selectUsersOnCheckboxChange(e) {
    const users: FormArray = this.form.get('users') as FormArray;
    if (e.checked) {
      users.push(new FormControl(e.source.value));
    } else {
      let i = 0;
      users.controls.forEach((item: FormControl) => {
        if (item.value.id === e.source.value.id) {
          users.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  selectUsergroupsOnCheckboxChange(e) {
    const usergroups: FormArray = this.form.get('usergroups') as FormArray;
    if (e.checked) {
      usergroups.push(new FormControl(e.source.value));
    } else {
      let i = 0;
      usergroups.controls.forEach((item: FormControl) => {
        if (item.value.id === e.source.value.id) {
          usergroups.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  selectAllUsers(e) {
    const users: FormArray = this.form.get('users') as FormArray;
    users.clear();
    if (e.checked) {
      this.users.map((value) => {
        users.push(new FormControl(value));
      });
    } else {
      users.clear();
    }
  }

  checkUserGroupsSelected(group) {
    const usergroups = this.form.get('usergroups').value;
    let found_index = -1;
    usergroups.find(function (element, index) {
      if (element.id === group.id) {
        found_index = index;
        return true;
      }
    });
    return found_index !== -1;
  }

  checkUserSelected(user) {
    const users = this.form.get('users').value;
    let found_index = -1;
    users.find(function (element, index) {
      if (element.id === user.id) {
        found_index = index;
        return true;
      }
    });
    return found_index !== -1;
  }

  unselectUser(user: User) {
    const users = this.form.get('users') as FormArray;
    let i = 0;
    users.controls.forEach((item: FormControl) => {
      if (item.value === user) {
        users.removeAt(i);
        return;
      }
      i++;
    });
  }

  searchUserbyKeyword() {
    this.form
      .get('search')
      .valueChanges.pipe(
        debounceTime(1000),
        takeUntil(this.destroyed$),
        switchMap((text) => this.usersService.searchUsersByKey(this.pagination, text, this.distributionTypeId))
      )
      .subscribe((response) => {
        this.users = response.data.data;
        if (this.postUsers) {
          const part = this.postUsers;
          this.users = this.users.filter(function (obj) {
            return !part.some(function (obj2) {
              return obj.id === obj2.id;
            });
          });
        }
      });
  }

  searchUser(event) {
    this.usersService
      .searchUsersByKey(this.pagination, event, this.distributionTypeId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        this.users = response.data.data;
        if (this.postUsers) {
          const part = this.postUsers;
          this.users = this.users.filter(function (obj) {
            return !part.some(function (obj2) {
              return obj.id === obj2.id;
            });
          });
        }
      });
  }

  getUsersFromUsergroups() {
    const groups = this.form.value.usergroups.map((val) => val.id).toString();
    this.usersService
      .getUsersFromUsergroups(groups, this.distributionTypeId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        this.users = response.data.data;
        if (this.postUsers) {
          const part = this.postUsers;
          this.users = this.users.filter(function (obj) {
            return !part.some(function (obj2) {
              return obj.id === obj2.id;
            });
          });
        }
      });
  }

  getUsers(distributionId?: number): void {
    this.usersService
      .getUsersForRecipients(this.distributionTypeId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => (this.users = res.data.data));
  }

  getUsergroups(distributionId?: number): void {
    this.usersService
      .getUsergroupsForRecipients(distributionId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => (this.usergroups = res.data.data));
  }
}
