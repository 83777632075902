import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AuthService } from '@sociuu/modules';

import { CoreComponent } from '../core.component';

import { JwtHelperService } from '@auth0/angular-jwt';

import { IMagicLogin } from '@lib/auth-token.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends CoreComponent implements CanActivate {
  private helper = new JwtHelperService();

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {
    super();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const token: boolean = !!localStorage.getItem('token');
    const magicToken: string = route.queryParams['magic-token'];
    const data: { token: string } = { token: magicToken };
    const tree: UrlTree = this.router.parseUrl(state.url);
    delete tree.queryParams['magic-token'];
    const returnUrl: string = tree.toString();
    const queryParams = (state.url === '/dashboard') ? {} : { queryParams: { returnUrl: returnUrl } };
    const isTokenExpired: boolean = this.helper.isTokenExpired(this.authService.getTokenFromStorage());
    localStorage.removeItem('isBecomeUser');
    localStorage.removeItem('becomeUserExpireTime');

    return of(token)
    .pipe(
      takeUntil(this.destroyed$),
      switchMap((response: boolean): Observable<boolean | UrlTree> => {
        if (response && !isTokenExpired) return of(route.queryParams['magic-token'] ? tree : true);
        else if (!!magicToken) {
          return this.authService.magicLogin(data)
            .pipe(
              tap((response: IMagicLogin): void => {
                localStorage.setItem('token', response?.token);
                if (response.isAdmin2fa === 1) localStorage.setItem('magicTokenResponse', JSON.stringify(response));
              }),
              map((response: IMagicLogin): boolean | UrlTree => {
                if (response.isAdmin2fa === 0) return route.queryParams['magic-token'] ? tree : true;
                else {
                  this.authService.logout();
                  this.router.navigate(['/login'], queryParams);
                  return false;
                }
              }),
              catchError(() => this.unAuthorizedUser(queryParams)),
            );
        }
        else this.unAuthorizedUser(queryParams);
      }),
      catchError(() => this.unAuthorizedUser(queryParams)),
    )
  }

  private unAuthorizedUser(queryParams: any): Observable<boolean> {
    this.authService.logout();
    this.router.navigate(['/login'], queryParams);
    return of(false);
  }
}
