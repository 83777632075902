<!-- Notifications toggle -->
<button mat-icon-button (click)="openPanel()" #notificationsOrigin>
  <ng-container *ngIf="numOfUnreadNotifications > 0">
    <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
      <span
        class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-teal-600 text-indigo-50 text-xs font-medium"
      >
        {{ numOfUnreadNotifications }}
      </span>
    </span>
  </ng-container>
  <mat-icon [svgIcon]="'heroicons_outline:bell'"></mat-icon>
</button>

<!-- Notifications panel -->
<ng-template #notificationsPanel>
  <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">
    <!-- Header -->
    <div class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary">
      <div class="sm:hidden -ml-1 mr-3">
        <button mat-icon-button (click)="closePanel()">
          <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:x'"></mat-icon>
        </button>
      </div>
      <div class="text-lg font-medium leading-10">Notifications</div>
      <div class="ml-auto">
        <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:mail-open'"></mat-icon>
      </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">
      <!-- Notifications -->
      <ng-container *ngFor="let notification of notifications; let i = index">
        <div
          class="flex w-full group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5"
          [ngClass]="{ unread: notification.status === 'unread' }"
        >
          <!-- Notification without a link -->
          <ng-container>
            <div class="flex flex-col w-full py-5 px-6">
              <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
            </div>
          </ng-container>
        </div>

        <!-- Notification content template -->
        <ng-template #notificationContent>
            <ng-container *ngIf="notification.title">
              <div class="flex justify-between items-center">
                <div
                  class="flex flex-100 font-semibold line-clamp-1"
                  [matTooltip]="notification.title"
                  [innerHTML]="notification.title"
                ></div>

                <!-- Actions -->
                <div class="relative flex flex-col flex-10 mr-6 ml-2">
                  <!-- Indicator -->
                  <button
                    class="w-6 h-6 min-h-6"
                    mat-icon-button
                    (click)="markNotificationAsRead(notification, notification.notification_from, i)"
                    [matTooltip]="notification.status === 'read' ? 'Mark as unread' : 'Mark as read'"
                  >
                    <span
                      class="w-2 h-2 rounded-full"
                      [ngClass]="{
                        'bg-gray-400 dark:bg-gray-500 sm:opacity-0 sm:group-hover:opacity-100': notification.status === 'read',
                        'bg-primary': notification.status === 'unread'
                      }"
                    ></span>
                  </button>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="notification.description">
              <div
                #description
                [innerHTML]="notification.description"
              ></div>
              <div
                #readMoreBtn
                class="underline text-blue-800 cursor-pointer"
                (click)="readMoreHandler($event, notification)"
              >
                {{ notification.isReadMoreEnabled ? 'Read less' : 'Read more' }}
              </div>
            </ng-container>
            <div class="mt-2 text-sm leading-none font-semibold">By {{ notification.created_by }}</div>
            <div class="mt-2 text-sm leading-none text-secondary">
              {{ notification.created_at | date : "MMM dd, h:mm a" }}
            </div>
        </ng-template>
      </ng-container>

      <!-- No notifications -->
      <ng-container *ngIf="!notifications || !notifications.length">
        <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
          <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100">
            <mat-icon class="text-primary-500-700" [svgIcon]="'heroicons_outline:bell'"></mat-icon>
          </div>
          <div class="mt-5 text-2xl font-semibold tracking-tight">No notifications</div>
          <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">
            When you have notifications, they will appear here.
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
