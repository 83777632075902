import { environment } from '@utility/app.util';
import { BehaviorSubject, Observable, map, take, tap } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { IPersonio, IPersonioResponses, IPersonioUpdate } from './create-source-new/create-source-new.component';

import {
  Source,
  CompanyPage,
  Integration,
  SourceApiResponse,
  VideoAnalyticsNetwork,
  FalconContentFilterDto,
  FalconChannelsFilterDto,
} from '@sociuu/interfaces';
import { ITag } from '@lib/tag.interface';

export interface IStatusesResponse {
  id: number;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class SettingsContentHubService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  private sources$: BehaviorSubject<SourceApiResponse> = new BehaviorSubject<SourceApiResponse>(null);

  public get sources(): Observable<SourceApiResponse> {
    return this.sources$.asObservable();
  }

  constructor(private http: HttpClient) {}

  public getSources(): void {
    this.http.get<SourceApiResponse>(`${environment.apiUrl}/content-source`)
      .pipe(
        take(1),
        map((response: SourceApiResponse) => ({
          ...response,
          data: {
            ...response.data,
            sources: response.data.sources.map((source: Source) => ({
              ...source,
              tags: source.tags.map((tag): ITag => ({
                id: tag.id,
                title: tag.tag_name,
                tagName: tag.tag_name,
                clientId: tag.client_id,
                createdAt: tag.created_at,
                updatedAt: tag.updated_at,
              }))
            }))
          }
        })),
        tap((response: SourceApiResponse): void => this.sources$.next(response)),
      ).subscribe();
  }

  public creteSource(body): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/content-source/create`, body, this.httpOptions);
  }

  public createPersonioSource(body: IPersonio): Observable<IPersonioResponses> {
    return this.http.post<IPersonioResponses>(`${environment.apiUrl}/v2/sources/personio`, body, this.httpOptions);
  }

  public updateSource(id, body): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/content-source/update/${id}`, body, this.httpOptions);
  }

  public updatePersonioSource(body: IPersonioUpdate): Observable<IPersonioResponses> {
    return this.http.put<IPersonioResponses>(`${environment.apiUrl}/v2/sources/personio`, body);
  }

  public getNetworks(): Observable<{ data: VideoAnalyticsNetwork[]; success: boolean }> {
    return this.http.get<{
      data: VideoAnalyticsNetwork[];
      success: boolean;
    }>(`${environment.apiUrl}/content-source/network-list`);
  }

  getSocialIntegrations(id: number): Observable<{ data: { integrations: Integration[]; can_add: boolean }; success: boolean }> {
    return this.http.get<{
      data: { integrations: Integration[]; can_add: boolean };
      success: boolean;
    }>(`${environment.apiUrl}/content-source/social-integrations/${id}`);
  }

  getCompanyPages(userId: number, networkId: number): Observable<{ data: CompanyPage[]; success: boolean }> {
    return this.http.get<{ data: CompanyPage[]; success: boolean }>(
      `${environment.apiUrl}/social/get-company-pages/${networkId}/${userId}`
    );
  }

  deleteSource(id: number): Observable<Source> {
    return this.http.delete<Source>(`${environment.apiUrl}/v2/content-source/delete/${id}`);
  }

  getFalconNetworks(): Observable<FalconContentFilterDto> {
    return this.http.get<FalconContentFilterDto>(`${environment.apiUrl}/content-source/falcon/network-list`);
  }

  getFalconChannels(): Observable<FalconChannelsFilterDto> {
    return this.http.get<FalconChannelsFilterDto>(`${environment.apiUrl}/content-source/falcon/channels`);
  }

  getFalconLabels(): Observable<FalconContentFilterDto> {
    return this.http.get<FalconContentFilterDto>(`${environment.apiUrl}/content-source/falcon/labels`);
  }

  searchFalconLabels(keyword): Observable<FalconContentFilterDto> {
    let params = new HttpParams();
    params = params.append('name', `${keyword}`);

    return this.http.get<FalconContentFilterDto>(`${environment.apiUrl}/content-source/falcon/search-labels`, { params });
  }

  public getStatusList(): Observable<IStatusesResponse[]> {
    return this.http.get<IStatusesResponse[]>(`${environment.apiUrl}/content-source/statuses`);
  }

  public reconnect(sourceId: number, authFlow: boolean = false): Observable<{ data: any[] }> {
    let params = new HttpParams();
    params = params.append('auth_completed', `${authFlow}`);

    return this.http.post<{ data: any[] }>(`${environment.apiUrl}/v2/source/${sourceId}/reconnect`, params);
  }
}
