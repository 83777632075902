import { Observable, of, tap } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AuthService } from '@admin/auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SociuuhubGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    this.authService.isSociuuhub = true;
    return of(this.authService.isSociuuhub)
      .pipe(
        tap((response: boolean): void => {
          if (!response) this.router.navigate(['/login']);
        })
      );
  }
}
