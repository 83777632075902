import { ValidationErrors, Validator, FormControl } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MinUppercase implements Validator {
  validate(formControl: FormControl): ValidationErrors | null {
    const password = formControl.value;
    const pattern = /^(?=.*[A-Z]).*$/g;

    if (pattern.test(password)) {
      return null;
    } else {
      return { min_upper_case: true };
    }
  }
}
