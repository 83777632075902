let env = 'production';
let apiUrl = 'https://api.sociuu.app/api';
let baseUrl = 'https://api.sociuu.app';
let production = true;
let sociuuhubApiUrl = 'https://api.sociuu.app/sociuuhub';

export let environment = {
  env: env,
  apiUrl: apiUrl,
  baseUrl: baseUrl,
  production: production,
  sociuuhubApiUrl: sociuuhubApiUrl,
  intercomAppId: 'knxbdngc',
  sociuu_demo_instance: false,
};