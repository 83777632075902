import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { DashboardService } from '@app/modules/admin/dashboard/dashboard.service';

@Injectable({
  providedIn: 'root',
})
export class UserProfileResolver implements Resolve<any> {
  constructor(private dashboardService: DashboardService) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const token: string = route.data.isSociuuhub ? localStorage.getItem('tokenUser') : localStorage.getItem('token');
    if (state.url.includes('magic-login')) return of(true);
    return token ? this.dashboardService.getUserProfile() : of(false);
  }
}
