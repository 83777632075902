import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TextToHtmlPipe } from './text-to-html-pipe.pipe';

@NgModule({
    declarations: [TextToHtmlPipe],
    exports: [TextToHtmlPipe],
    imports: [CommonModule],
})
export class TextToHtmlPipeModule {}
