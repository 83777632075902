import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AuthService } from '@admin/auth/services/auth.service';

import { ClientInfoDto, ClientInfoResponseDto } from '@sociuu/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ClientInfoGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.clientInfo.pipe(
      switchMap((response: ClientInfoDto): Observable<boolean> => {
        if (response) return of(true);
        else return this.authService.getClientInfo().pipe(map((response: ClientInfoResponseDto): boolean => response?.success));
      })
    );
  }
}
