import { Route } from '@angular/router';

import { LayoutComponent } from '@app/layout/layout.component';
import { SociuuhubComponent } from './modules/sociuuhub/sociuuhub.component';

import { AuthGuard } from '@guards/auth.guard';
import { SociuuhubGuard } from './core/guards/sociuuhub.guard';
import { ClientInfoGuard } from './core/guards/client-info.guard';

import { InitialDataResolver } from '@resolvers/app.resolvers';
import { UserProfileResolver } from './resolvers/user-profile.resolver';
import { CountSettingsResolver } from '@resolvers/count-settings.resolver';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  {
    path: '',
    canActivate: [ClientInfoGuard],
    children: [
      // Public routes
      {
        path: 'public',
        loadChildren: () => import('app/modules/public/public.module').then((m) => m.PublicModule),
      },

      // Preview routes
      {
        path: 'preview',
        loadChildren: () => import('app/modules/admin/post-preview/post-preview.module').then((m) => m.PostPreviewModule),
      },

      // Sociuuhub routes
      {
        path: 'sociuuhub',
        data: { isSociuuhub: true },
        component: SociuuhubComponent,
        canActivate: [SociuuhubGuard],
        loadChildren: () => import('app/modules/sociuuhub/sociuuhub.module').then((m) => m.SociuuhubModule),
      },

      // Admin routes
      {
        path: '',
        component: LayoutComponent,
        resolve: {
          initialData: InitialDataResolver,
          userProfile: UserProfileResolver,
          countSettings: CountSettingsResolver,
        },
        canActivate: [AuthGuard],
        data: {
          isAdmin: true,
          isHubspotEnabled: true,
        },
        loadChildren: () => import('app/modules/admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'login',
        loadChildren: () => import('app/modules/admin/auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'reset/:token',
        loadChildren: () => import('app/modules/admin/activation-reset/activation-reset.module').then((m) => m.ActivationResetModule),
      },
      {
        path: 'activation/:token',
        loadChildren: () => import('app/modules/admin/activation-reset/activation-reset.module').then((m) => m.ActivationResetModule),
      },
      { path: 'signup', redirectTo: 'sociuuhub/signup' },
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
      { path: 'close', pathMatch: 'full', redirectTo: 'public/close' },
      { path: '**', redirectTo: 'dashboard' },
    ],
  },
];
