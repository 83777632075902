import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TruncateTooltipDirective } from './trucnated-with-tooltip.directive';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [TruncateTooltipDirective],
  imports: [
    CommonModule,
    MatTooltipModule,
  ],
  exports: [TruncateTooltipDirective]
})
export class TruncateWithTooltipDirectiveModule { }
