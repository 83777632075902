import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoadImageDirective } from './load-image.directive';



@NgModule({
  declarations: [LoadImageDirective],
  imports: [
    CommonModule
  ],
  exports: [LoadImageDirective]
})
export class LoadImageModule { }
