import { Component, OnInit } from '@angular/core';

import { AuthService as AdminAuthService } from '@sociuu/admin/auth/services/auth.service';

@Component({
  selector: 'sociuu-sociuuhub',
  templateUrl: './sociuuhub.component.html',
  styleUrls: ['./sociuuhub.component.scss']
})
export class SociuuhubComponent implements OnInit {

  constructor(private adminAuthService: AdminAuthService) { }

  public ngOnInit(): void {
    this.expireBecomeUserToken();
    window.HubSpotConversations?.widget.remove();
  }

  private expireBecomeUserToken(): void {
    if (this.adminAuthService.isBecomeUserEnabled) this.adminAuthService.expireBecomeUserToken();
  }
}
