import { Observable } from 'rxjs';
import { LoginResponseDto, RefreshTokenDto } from '@sociuu/interfaces';

export abstract class AbstractAuthService {
  abstract login(
    email: string,
    password: string
  ): Observable<LoginResponseDto>

  abstract logout(): void;

  abstract refreshUserToken(): Observable<RefreshTokenDto>;

  abstract getTokenFromStorage(): string;
}
