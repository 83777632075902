import { Pipe, PipeTransform } from '@angular/core';

import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  transform(value: any, dateFmt: string = 'DD.MM.YYYY HH:mm', args?: any): any {
    if(!value) return '';
    return dayjs.utc(value).local().format(dateFmt);
  }
}
