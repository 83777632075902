import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateRangeModalComponent } from './date-range-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomDateAdapter } from '@sociuu/utility';



@NgModule({
  declarations: [DateRangeModalComponent],
  exports: [
    DateRangeModalComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatButtonModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [{provide: DateAdapter, useClass: CustomDateAdapter }]
})
export class DateRangeModalModule { }
