import isNil from 'lodash/isNil';
import { environment } from '@utility/app.util';
import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatDialogRef } from '@angular/material/dialog';

import { ConfirmationModalComponent } from '@app/shared/confirmation-modal/confirmation-modal.component';

import { ConfirmationModalService } from '@app/shared/confirmation-modal/confirmation-modal.service';

import {
  UsersResponseDto,
  AuthorsResponseDto,
  DefaultResponseDto,
  PostsListResponseDto,
  SharePostResponseDto,
  ContentHubPreviewPost,
  PostDetailsResponseDto,
} from '@sociuu/interfaces';
import { IResendPostToNewUserPayload, IResendPostsPayload } from '@lib/posts.interface';

import { resendEmailsConfirmationConfig } from '@app/shared/confirmation-modal/util';

@Injectable({
  providedIn: 'root',
})
export class PostsService {
  page = 1;
  pagination = 10;
  sort_by = 'created_at';
  sort_order = 'desc';
  filters = {
    published: 1,
    archived: 0,
    sort_order: this.sort_order,
    sort_by: this.sort_by,
    key: '',
    networks: [],
    authors: [],
    interval: '',
    dateFrom: '',
    dateTo: '',
  };
  filterSubject = new BehaviorSubject({ ...this.filters });
  currentFilters = this.filterSubject.asObservable();

  constructor(
    private http: HttpClient,
    private _confirmationModalService: ConfirmationModalService,
  ) {}

  resetFilters(): void {
    this.filterSubject.next({ ...this.filters });
  }

  getPosts(page: number, filters: any, campaignType: string): Observable<PostsListResponseDto> {
    let str = '';
    this.filterSubject.next(filters);
    for (const p in filters) {
      if (filters.hasOwnProperty(p)) {
        str += p + '=' + filters[p] + '&';
      }
    }
    return this.http.get<PostsListResponseDto>(
      `${environment.apiUrl}/posts/list?page=${page}&pagination=${this.pagination}&campaign_type=${campaignType}&${str}`
    );
  }

  getPostsWebhook(page: number, filters: any, campaignType: string): Observable<PostsListResponseDto> {
    let str = '';
    for (const p in filters) {
      if (filters.hasOwnProperty(p)) {
        str += p + '=' + filters[p] + '&';
      }
    }
    return this.http.get<PostsListResponseDto>(
      `${environment.apiUrl}/posts/list/webhooks?page=${page}&pagination=${this.pagination}&campaign_type=${campaignType}&${str}`
    );
  }

  getPostsSociuuhub(page: number, filters: any, isArchived: boolean): Observable<PostsListResponseDto> {
    let str = '';
    for (const p in filters) {
      if (filters.hasOwnProperty(p) && !['published', 'archived', 'authors'].includes(p)) {
        str += p + '=' + filters[p] + '&';
      }
    }
    return this.http.get<PostsListResponseDto>(
      `${environment.apiUrl}/posts/list/pull?page=${page}&pagination=${this.pagination}&${str}&archived=${isArchived}`
    );
  }

  getPostDetails(post_id: number): Observable<PostDetailsResponseDto> {
    return this.http.get<PostDetailsResponseDto>(`${environment.apiUrl}/posts/details/${post_id}`);
  }

  getPostDetailsWebhook(post_id: number): Observable<PostDetailsResponseDto> {
    return this.http.get<PostDetailsResponseDto>(`${environment.apiUrl}/posts/details/webhooks/${post_id}`);
  }

  deletePost(post_id: number): Observable<DefaultResponseDto> {
    return this.http.delete<DefaultResponseDto>(`${environment.apiUrl}/posts/delete/${post_id}`);
  }

  deleteMultiplePosts(ids: number[]): Observable<DefaultResponseDto> {
    const data = { ids: ids };
    return this.http.post<DefaultResponseDto>(`${environment.apiUrl}/posts/delete-multiple`, data);
  }

  public resendPostsToNonOpeners(post_id: number, sendNow?: boolean): Observable<DefaultResponseDto> {
    const payload: IResendPostsPayload = {
      id: post_id,
      ...(!isNil(sendNow) && { send_now: sendNow }),
    };
    return this.http.post<DefaultResponseDto>(`${environment.apiUrl}/posts/emails/non-openers`, payload);
  }

  getAuthors(): Observable<AuthorsResponseDto> {
    return this.http.get<AuthorsResponseDto>(`${environment.apiUrl}/users/get-authors?pagination=99`);
  }

  public archivePosts(action: number, ids: number[]): Observable<any> {
    const status: string = action === 2 ? 'archive' : 'unarchive';
    return this.http.put(`${environment.apiUrl}/v2/posts/${status}`, { ids });
  }

  exportStats(
    date_from: string,
    date_to: string,
    interval = '365d',
    campaign_type: string,
    post_type = 'regular',
    export_type: 'csv' | 'xlsx' = 'xlsx',
  ): Observable<DefaultResponseDto> {
    const data = {
      date_from,
      date_to,
      interval,
      campaign_type,
      post_type,
      fileType: export_type,
    };
    return this.http.post<DefaultResponseDto>(`${environment.apiUrl}/statistics/export`, data);
  }

  exportPostList(
    date_from: string,
    date_to: string,
    interval = '365d',
    campaign_type: string,
    post_type = 'regular',
    export_type: 'csv' | 'xlsx' = 'xlsx',
  ): Observable<DefaultResponseDto> {
    const data = {
      date_from,
      date_to,
      interval,
      campaign_type,
      post_type,
      fileType: export_type,
    };
    return this.http.post<DefaultResponseDto>(`${environment.apiUrl}/posts/export`, data);
  }

  uploadImageToS3(link): Observable<any> {
    const postedData = { link: link };
    return this.http.post(environment.apiUrl + '/posts/uploadUrlImage', postedData);
  }

  getContentHubPosts(ids: string): Observable<{
    data: ContentHubPreviewPost[];
    deleted: ContentHubPreviewPost[];
  }> {
    let params = new HttpParams();
    params = params.append('ids', ids);

    return this.http.get<{
      data: ContentHubPreviewPost[];
      deleted: ContentHubPreviewPost[];
    }>(`${environment.apiUrl}/content-source/content-by-ids`, { params });
  }

  getContentHubPostsAndContents(post_ids: string, content_ids: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('post_ids', post_ids);
    params = params.append('content_ids', content_ids);

    return this.http.get<any>(`${environment.apiUrl}/v01/posts/duplicate`, {
      params,
    });
  }

  resendPostToNewUsers(data: IResendPostToNewUserPayload): Observable<DefaultResponseDto> {
    return this.http.post<DefaultResponseDto>(`${environment.apiUrl}/v01/posts/update-users`, data);
  }

  getAllPostUsers(post_id: number): Observable<UsersResponseDto> {
    return this.http.get<UsersResponseDto>(
      `${environment.apiUrl}/v01/users/list/byPost/${post_id}?page=${this.page}&pagination=${9999}`
    );
  }

  sharePost(event: string, shared_source, token): Observable<SharePostResponseDto> {
    const data = {
      event: event,
      token: token,
      shared_source: shared_source,
    };

    return this.http.post<SharePostResponseDto>(`${environment.apiUrl}/post/flag/create`, data);
  }

  getPostPreview(token): Observable<any> {
    return this.http.get(`${environment.apiUrl}/posts/getPreview?token=${token}`);
  }

  getScreenShot(token): Observable<any> {
    const url = environment.apiUrl.split('/api').splice(0, 2).join('/api');
    return this.http.get(`${url}/public/screenshot?token=${token}`);
  }

  getPostDetailsSociuuhub(post_id: number, campaign_type: string): Observable<PostDetailsResponseDto> {
    return this.http.get<PostDetailsResponseDto>(`${environment.apiUrl}/posts/details/pull/${post_id}?campaign_type=${campaign_type}`);
  }

  sendPostPreview(data): Observable<DefaultResponseDto> {
    return this.http.post<DefaultResponseDto>(`${environment.apiUrl}/posts/preview-new`, data);
  }

  public resendEmailToNonOpenersConfirmationDialog(): Promise<any> {
    let dialogRef: MatDialogRef<ConfirmationModalComponent, any>;
    dialogRef = this._confirmationModalService.open(resendEmailsConfirmationConfig());
    return new Promise((res, rej) => {
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'confirmed') res(true);
        else if (result === 'cancelled') res(false);
      });
    });
  }
}
