import * as moment from 'moment';
import { environment } from '@utility/app.util';
import { BehaviorSubject, Observable, ReplaySubject, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';

import * as Sentry from "@sentry/angular-ivy";

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpParams } from '@angular/common/http';

import { IconSnackBarComponent } from '@app/utility/index.icon-snack-bar';

import { JwtHelperService } from '@auth0/angular-jwt';
import { AbstractAuthService } from './abstract-auth.service';
import { I18nService } from '@app/core/transloco/i18n.service';
import { AuthService as SociuuhubAuthService } from '@sociuuhub/modules/auth/services/auth.service';

import {
  UserInfoDto,
  ClientInfoDto,
  RefreshTokenDto,
  LoginResponseDto,
  DefaultResponseDto,
  ClientInfoResponseDto,
} from '@sociuu/interfaces';
import { IBecomeUser, IBecomeUserServer } from '@lib/become-user.interface';
import { IMagicLogin, IMagicLoginServer, ILogin, ILoginServer, IAccountReset, IAccountUpdate, IAccountUpdatePayload } from '@lib/auth-token.interface';

import { INTEGRATIONS } from '@app/utility/app.util';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends AbstractAuthService {
  public isSociuuhub: boolean = false;

  private _currentUser$: BehaviorSubject<UserInfoDto> = new BehaviorSubject<UserInfoDto>(null);

  private _currentTokenUser: string;
  private _helper = new JwtHelperService();
  private _hubspotToken$: BehaviorSubject<string> = new BehaviorSubject(null);
  public hubspotToken: Observable<string> = this._hubspotToken$.asObservable();

  private _clientInfo$ = new BehaviorSubject<ClientInfoDto>(null);
  private _isActimoIntegrationEnabled: ReplaySubject<boolean> = new ReplaySubject(1);
  private _isBrandwatchIntegrationEnabled: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public get clientInfo(): BehaviorSubject<ClientInfoDto> {
    return this._clientInfo$;
  }

  public get isSociuuHubEnabled(): boolean {
    return !!this.clientInfo.value.is_sociuuhub;
  }

  public get isOptInFeatureEnabled(): boolean {
    return !!this.clientInfo.value.opt_in;
  }

  public get isIndividualStatsEnabled(): boolean {
    return !!this.clientInfo.value.individual_stats;
  }

  public get isSMSEnabled(): boolean {
    return !!this.clientInfo?.value?.distributions?.some((distribution: string): boolean => distribution === 'sms');
  }

  public get isSMSCreditsAvailable(): boolean {
    return this.isSMSEnabled && this.clientInfo?.value?.available_credit >= 1;
  }

  public get isSelfSignupFeatureEnabled(): boolean {
    return this.clientInfo?.value?.features?.self_signup;
  }

  public get isSocialConnectFeatureEnabled(): boolean {
    return this.clientInfo?.value?.features?.social_connect;
  }

  public get isUsergroupCountryAssociationFeatureEnabled(): boolean {
    return this.clientInfo?.value?.features?.usergroup_country_association;
  }

  public get isBrandwatchIntegrationEnabled(): BehaviorSubject<boolean> {
    return this._isBrandwatchIntegrationEnabled;
  }

  public get isActimoIntegrationEnabled(): ReplaySubject<boolean> {
    return this._isActimoIntegrationEnabled;
  }

  public get currentUser(): BehaviorSubject<UserInfoDto> {
    return this._currentUser$;
  }

  public set isSociuuHubEnabled(data: boolean) {
    this._clientInfo$.next({
      ...this._clientInfo$.value,
      is_sociuuhub: data ? 1 : 0,
    });
  }

  public get currentTokenUser(): string {
    return this._currentTokenUser;
  }

  public set currentTokenUser(token: string) {
    this._currentTokenUser = token;
  }

  public get isBecomeUserEnabled(): boolean {
    return localStorage.getItem('isBecomeUser') === 'true';
  }

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private i18nService: I18nService,
    private sociuuhubAuthService: SociuuhubAuthService,
  ) {
    super();
  }

  public magicLogin(data: { token: string }): Observable<IMagicLogin> {
    return this.http
      .post(`${environment.apiUrl}/v2/magic-login`, data)
      .pipe(map((response: { data: IMagicLoginServer }): IMagicLogin => this.transformLoginData(response.data)));
  }

  public changeWelcomeStatus(): Observable<any> {
    return this.http.get(`${environment.sociuuhubApiUrl}/user/change-welcome-status`);
  }

  public login(email: string, password: string): Observable<ILogin> {
    const data = {
      email: email,
      password: password,
    };

    return this.http.post<ILoginServer>(`${environment.apiUrl}/login`, data)
      .pipe(
        map((response: ILoginServer): ILogin => {
          return {
            success: response.success,
            data: this.transformLoginData(response.data),
            messages: response?.messages,
            changePassword: response.change_password,
            token: response.token,
          };
        })
      );
  }

  public enable2fa(secret: string, user_id: number): Observable<any> {
    const data = { secret, user_id };
    return this.http.post<LoginResponseDto>(`${environment.apiUrl}/enable2fa`, data);
  }

  public getBecomeUserToken(userId: number): Observable<IBecomeUser> {
    return this.http.get<IBecomeUserServer>(`${environment.apiUrl}/v2/sociuuhub/become/${userId}`).pipe(
      map(
        (response: IBecomeUserServer): IBecomeUser => ({
          token: response.data.token,
          becomeUser: response.data.become_user,
          becomeBy: response.data.become_by,
        })
      )
    );
  }

  public getClientInfo(): Observable<ClientInfoResponseDto> {
    return this.http.get<ClientInfoResponseDto>(`${environment.apiUrl}/client/info`)
      .pipe(
        tap((response: ClientInfoResponseDto) => {
          this.isBrandwatchIntegrationEnabled
            .next(response.data.integrations?.some((item: string): boolean => item === INTEGRATIONS.BRANDWATCH));
          this.isActimoIntegrationEnabled
            .next(response.data.integrations?.some((item: string): boolean => item === INTEGRATIONS.ACTIMO));
        }),
        tap((clientInfo: ClientInfoResponseDto) => {
          if (clientInfo.success) {
            this.i18nService.setLocale(clientInfo?.data.i18n);
            this.clientInfo.next(clientInfo?.data);
          }
        }),
      )
  }

  refreshUserToken(): Observable<RefreshTokenDto> {
    return this.http.get<RefreshTokenDto>(`${environment.apiUrl}/auth/refresh`);
  }

  public getTokenFromStorage(): string {
    return localStorage.getItem('token');
  }

  public expireBecomeUserToken(): void {
    if (!this.isSociuuhub && this.isBecomeUserEnabled) return;

    const currentTime: number = moment(new Date(), 'M/D/YYYY H:mm').unix();
    const expireTime: number = +localStorage.getItem('becomeUserExpireTime');
    const expireAfter: number = expireTime - currentTime;
    let timeout: any;

    if (expireAfter > 0) {
      timeout = setTimeout((): void => {
        clearTimeout(timeout);
        this.sociuuhubAuthService.logout();
      }, expireAfter * 1000);
    } else {
      clearTimeout(timeout);
      this.sociuuhubAuthService.logout();
    }
  }

  public logout(): void {
    Sentry.setUser(null);
    if (this.isBecomeUserEnabled) this.openCustomSnackBar('Your session has been expired.', 'info');

    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('supportToken');
    this._currentUser$.next(null);
    this._hubspotToken$.next(null);
  }

  public validateUserToken(token: string, type: string): Observable<DefaultResponseDto> {
    let params = new HttpParams();
    params = params.append('token', `${token}`);
    params = params.append('type', `${type}`);
    return this.http.get<DefaultResponseDto>(`${environment.apiUrl}/account/validate`, { params: params }).pipe(shareReplay());
  }

  private transformLoginData(loginData: IMagicLoginServer): IMagicLogin {
    return {
      token: loginData.token,
      is2fa: loginData.is_2fa,
      secret: loginData?.secret,
      isAdmin2fa: loginData.is_admin_2fa,
      google2faUrl: loginData?.google2fa_url,
      userId: loginData?.user_id,
    };
  }

  public setHubspotToken(token: string): void {
    this._hubspotToken$.next(token);
  }

  public getHubspotToken() {
    return this.http.post<any>(`${environment.apiUrl}/v2/hubspot/chat/token/create`, {});
  }

  public checkHubspotToken(): Observable<string> {
    const supportToken: string = localStorage.getItem('supportToken');
    if (supportToken && !this._helper.isTokenExpired(supportToken)) {
      this.setHubspotToken(supportToken);
      return this.hubspotToken;
    }
    else return this.refreshHubspotToken();
  }

  private refreshHubspotToken(): Observable<string> {
    return this.getHubspotToken()
      .pipe(
        takeUntil(this.destroyed$),
        map((result: { data: { token: string } }) => result.data.token),
        tap((supportToken: string) => {
          localStorage.setItem('supportToken', supportToken ?? '');
          this.setHubspotToken(supportToken);
        }),
        switchMap((): Observable<string> => this.hubspotToken),
        catchError((err) => {
          return of(null);
        })
      );
  }

  public activateUser(data: { email: string }): Observable<IAccountReset> {
    return this.http.post<{ data: IAccountReset }>(`${environment.baseUrl}/auth/account-reset/activation`, data)
      .pipe(map((response: { data: IAccountReset }): IAccountReset => response.data));
  }

  public resetPasswordUser(data: { email: string }): Observable<IAccountReset> {
    return this.http.post<{ data: IAccountReset }>(`${environment.baseUrl}/auth/account-reset/reset`, data)
      .pipe(map((response: { data: IAccountReset }): IAccountReset => response.data));
  }

  public updateAccount(data: IAccountUpdatePayload): Observable<IAccountUpdate> {
    return this.http.post<{ data: IAccountUpdate }>(`${environment.baseUrl}/auth/account-update`, data)
      .pipe(map((response: { data: IAccountUpdate }): IAccountUpdate => response.data));
  }

  private openCustomSnackBar(message: string, icon: string): void {
    this.snackBar.openFromComponent(IconSnackBarComponent, {
      data: {
        message: message,
        icon: icon,
      },
    });
  }
}
