import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '@utility/app.util';
import {
  RefreshTokenDto,
  LoginResponseDto,
  DefaultResponseDto,
} from '@sociuu/interfaces';
import { AbstractAuthService } from './abstract-auth.service';

export interface ISociuuhubUser {
  user: string;
  companyName: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService extends AbstractAuthService {
  private _currentUser$ = new BehaviorSubject<ISociuuhubUser>(null);

  public get currentUser(): BehaviorSubject<ISociuuhubUser> {
    return this._currentUser$;
  }

  constructor(private http: HttpClient, private router: Router) {
    super();
  }

  public signup(payload: any): Observable<any> {
    return this.http.post(`${environment.sociuuhubApiUrl}/user/signup`, payload);
  }

  sendMagicLink(field: string, type: 'email' | 'number' = 'email', returnURL?: string): Observable<any> {
    return this.http.get(
      `${environment.sociuuhubApiUrl}/user/send-magic-link?${type === 'email' ? 'email' : 'phone'}=${field}${returnURL ? `&returnUrl=${returnURL}` : ''}`
    );
  }

  verifyUser(
    signature: string,
    expires: string,
    token: string
  ): Observable<any> {
    return this.http.get(
      `${environment.sociuuhubApiUrl}/user/verify-login?token=${token}`
    );
  }

  login(email: string, password: string): Observable<LoginResponseDto> {
    const data = {
      email: email,
      password: password,
    };

    return this.http.post<LoginResponseDto>(
      `${environment.sociuuhubApiUrl}/login`,
      data
    );
  }

  refreshUserToken(): Observable<RefreshTokenDto> {
    return this.http.get<RefreshTokenDto>(`${environment.sociuuhubApiUrl}/auth/refresh`);
  }

  getTokenFromStorage(): string {
    return localStorage.getItem('tokenUser');
  }

  public logout(): void {
    localStorage.removeItem('tokenUser');
    localStorage.removeItem('isBecomeUser');
    localStorage.removeItem('becomeUserExpireTime');
    this.router.navigate(['/sociuuhub/login']);
  }

  public forceRefresh(): void {
    location.reload();
  }

  validateUserToken(
    token: string,
    type: string
  ): Observable<DefaultResponseDto> {
    let params = new HttpParams();
    params = params.append('token', `${token}`);
    params = params.append('type', `${type}`);
    return this.http.get<DefaultResponseDto>(
      `${environment.sociuuhubApiUrl}/account/validate`,
      { params: params }
    );
  }
}
