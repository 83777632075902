import { environment } from '@utility/app.util';

import { Observable, of } from 'rxjs';
import { isNil } from 'lodash';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';

import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { AuthService } from '../auth/services/auth.service';
import { UserPermissions, UserRoles, UserRolesEnum } from '@app/app.permissions';


import {
  EngagementDto,
  StatisticsDto,
  DefaultResponseDto,
  NotificationListDto,
  UserInfoResponseDto,
  EngagementStatsNewDto,
  DashboardStatsResponseDto,
  EngagementStatsResponseDto,
  EngagementStatsUsergroupsResponseDto,
  AdaptionRateDashboardStatsResponseDto,
  EngagementStatsDepartmentsResponseDto,
} from '@sociuu/interfaces';
import { IPostFrequency, IServerPostFrequency, IServerPostFrequencyData } from '@lib/dashboard.interface';

import { initQueryParams } from '@app/utility/app.util';
import { setSentryUser } from '@app/utility/sentry.utils';

export interface IUsergroupStatPayload {
  dateTo: string;
  groupBy: string;
  dateFrom: string;
  interval: string;
}

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  getDashboardStats(interval): Observable<DashboardStatsResponseDto> {
    if (interval.interval !== 'custom') {
      return this.http.get<DashboardStatsResponseDto>(
        `${environment.apiUrl}/statistics/dashboard?interval=${interval.interval}&groupBy=${interval.groupBy}`
      );
    } else {
      return this.http.get<DashboardStatsResponseDto>(
        `${environment.apiUrl}/statistics/dashboard?date_from=${interval.date_from}&date_to=${interval.date_to}&interval=custom&groupBy=${interval.groupBy}`
      );
    }
  }

  getAdoptionRateDashboardStats(interval): Observable<AdaptionRateDashboardStatsResponseDto> {
    if (interval.interval !== 'custom') {
      return this.http.get<AdaptionRateDashboardStatsResponseDto>(
        `${environment.apiUrl}/v2/statistics/adaption-rate?interval=${interval.interval}&groupBy=${interval.groupBy}`
      );
    } else {
      return this.http.get<AdaptionRateDashboardStatsResponseDto>(
        `${environment.apiUrl}/v2/statistics/adaption-rate?date_from=${interval.date_from}&date_to=${interval.date_to}&interval=custom&groupBy=${interval.groupBy}`
      );
    }
  }

  getPostFrequencyDashboardStats(interval): Observable<IPostFrequency[]> {
    let URL: string = `${environment.apiUrl}/v2/statistics/post-frequency?groupBy=${interval.groupBy}`;
    if (interval.interval !== 'custom') URL += `&interval=${interval.interval}`;
    else URL += `&date_from=${interval.date_from}&date_to=${interval.date_to}&interval=custom`;

    return this.http.get<IServerPostFrequency>(URL).pipe(
      map((response: IServerPostFrequency): IPostFrequency[] => {
        return response.data.map((item: IServerPostFrequencyData): IPostFrequency => this.tranformPostFrequency(item));
      })
    );
  }

  getNotificationList(): Observable<NotificationListDto> {
    return this.http.get<NotificationListDto>(`${environment.apiUrl}/notifications/list`);
  }

  markNotificationAsRead(client_notification_id: number): Observable<DefaultResponseDto> {
    return this.http.put<DefaultResponseDto>(`${environment.apiUrl}/notifications/mark-as-read/${client_notification_id}`, ``);
  }

  getStats(interval, with_posts = 'no'): Observable<StatisticsDto> {
    if (interval.interval !== 'custom') {
      return this.http.get<StatisticsDto>(
        `${environment.apiUrl}/statistics?interval=${interval.interval}&with_posts=${with_posts}`
      );
    } else {
      return this.http.get<StatisticsDto>(
        `${environment.apiUrl}/statistics?date_from=${interval.date_from}&date_to=${interval.date_to}&interval=custom&with_posts=${with_posts}`
      );
    }
  }

  public getUserProfile(): Observable<UserInfoResponseDto> {
    return this.http.get<UserInfoResponseDto>(`${environment.apiUrl}/users/profile`)
      .pipe(
        tap((res) => {
          this.authService.currentUser.next(res.data);
          this.assingeUserRole(res?.data?.roles?.slug);
          this.mapUserPermissions([...(res?.data?.roles?.permissions || [])]);
          setSentryUser({...res?.data, username: `${res?.data?.first_name} ${res?.data?.last_name}`});
        }),
        catchError(() => {
          this.router.navigate(['/login'], initQueryParams(this.router));
          return of(null);
        })
      );
  }

  private mapUserPermissions(permissions: any[]): void {
    permissions.forEach((permission) => {
      if (isNil(UserPermissions[permission.slug])) {
        console.warn('New permission found', permission.slug);
      }
      UserPermissions[permission.slug] = true;
    });
  }

  private assingeUserRole(currentRole: UserRolesEnum | string): void {
    UserRoles[currentRole] = true;
  }

  getEngagementStats(interval): Observable<EngagementDto> {
    if (interval.interval !== 'custom') {
      return this.http.get<EngagementDto>(
        `${environment.apiUrl}/engagement_stats?interval=${interval.interval}&groupBy=${interval.groupBy}`
      );
    } else {
      return this.http.get<EngagementDto>(
        `${environment.apiUrl}/engagement_stats?date_from=${interval.date_from}&date_to=${interval.date_to}&interval=custom&groupBy=${interval.groupBy}`
      );
    }
  }

  getNewEngagementStats(interval): Observable<EngagementStatsResponseDto> {
    if (interval.interval !== 'custom') {
      return this.http.get<EngagementStatsResponseDto>(
        `${environment.apiUrl}/v2/engagement_stats/engagements?interval=${interval.interval}&groupBy=${interval.groupBy}`
      );
    } else {
      return this.http.get<EngagementStatsResponseDto>(
        `${environment.apiUrl}/v2/engagement_stats/engagements?date_from=${interval.date_from}&date_to=${interval.date_to}&interval=custom&groupBy=${interval.groupBy}`
      );
    }
  }

  getEngagementDepartmentsStats(interval): Observable<EngagementStatsDepartmentsResponseDto> {
    if (interval.interval !== 'custom') {
      return this.http.get<EngagementStatsDepartmentsResponseDto>(
        `${environment.apiUrl}/v2/engagement_stats/departments?interval=${interval.interval}&groupBy=${interval.groupBy}`
      );
    } else {
      return this.http.get<EngagementStatsDepartmentsResponseDto>(
        `${environment.apiUrl}/v2/engagement_stats/departments?date_from=${interval.date_from}&date_to=${interval.date_to}&interval=custom&groupBy=${interval.groupBy}`
      );
    }
  }

  public getEngagementUsergroupsStats(data: IUsergroupStatPayload): Observable<EngagementStatsUsergroupsResponseDto> {
    let params = new HttpParams();

    params = params.append('groupBy', data.groupBy);
    params = params.append('interval', data.interval);
    if (data.interval === 'custom') {
      params = params.append('date_to', data.dateTo);
      params = params.append('date_from', data.dateFrom);
    }

    return this.http.get<EngagementStatsUsergroupsResponseDto>(`${environment.apiUrl}/v2/engagement_stats/usergroups`, { params });
  }

  getEngagementStatsNew(filterData): Observable<EngagementStatsNewDto> {
    let params = new HttpParams();

    if (filterData.pid !== null && filterData.pid !== '') {
      params = params.append('pid', filterData.pid);
    } else {
      if (filterData.interval !== 'custom') {
        if ((filterData.ugid !== null) !== null && filterData.ugid !== '') {
          params = params.append('ugid', filterData.ugid);
        }
        params = params.append('date', filterData.interval);
      } else {
        if ((filterData.ugid !== null) !== null && filterData.ugid !== '') {
          params = params.append('ugid', filterData.ugid);
        }
        params = params.append('date', filterData.interval);
        params = params.append('date_from', filterData.date_from);
        params = params.append('date_to', filterData.date_to);
      }
    }

    if (filterData.export) {
      params = params.append('export', filterData.export);
    }
    return this.http.get<EngagementStatsNewDto>(`${environment.apiUrl}/engagement_stats_new`, { params }).pipe(shareReplay(1));
  }

  private tranformPostFrequency(response: IServerPostFrequencyData): IPostFrequency {
    return {
      fullDate: response.full_date,
      postsSent: response.posts_sent,
      xValue: response.x_value,
    };
  }
}
