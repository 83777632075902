export default {
  // Magic Login Page
  "Request Magic Login": "Bekræft min adgang",
  "To make life easy we've skipped lengthy and difficult passwords. Enter your email and check your inbox for an email with a login link.": "Indsæt din email adresse og modtag en email med adganglink til din personlige Content Hub.",
  "Email Address": "Email Adresse",
  "Email is required.": "Email er påkrævet.",
  "Invalid Email": "Ugylding email.",
  "Send magic link": "Send adgangslink",
  "Welcome to your<br/>Content Hub": "Velkommen til din</br>Content Hub",
  "This is your personal Content Hub, where you will find our latest news, social media posts, and job listings that you can share with your network. Enter your email address to gain access.": "Dette er din personlige Content Hub, hvor du finder vores seneste nyheder, opslag fra sociale medier og jobopslag du kan dele med dit netværk. Indsæt din mail adresse og få adgang.",

  // Magic Verify Page
  "Verifying user!": "Verifying user!",

  // Signup Page
  "Signup": "",
  "Signin": "",
  "Sign In": "",
  "Already have an account?": "",
  "First name": "",
  "Last name": "",
  "Email address": "",
  "Phone": "",
  "Department": "",
  "Department is required": "",
  "I agree to the": "",
  "Welcome to": "",
  "Create your account": "",
  "Invalid phone number": "",
  "Allowed domains i.e;": "",
  "Email address or Phone number is required": "",
  "Invalid domain": "",
  "Please enter a valid email address": "",
  "Last name is required": "",
  "Whitespaces are not allowed": "",
  "First name is required": "",

  // Welcome modal
  "Hi {{ userName }}": "Hi {{ userName }}",
  "Welcome to our social media hub. Take part in sharing all our great stories, news, job posts and more.": "I denne Content Hub finder du vores seneste nyheder, opslag fra sociale medier, ledige jobs og meget mere. Det hele er klar til at blive delt..",
  "Every share counts.": "Alle delinger tæller.",
  "Get started right here": "Gå på opdagelse her",
};
