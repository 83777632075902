import { environment } from '@utility/app.util';
import { Observable, Subject, map, takeUntil } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import {
  ListUsersResponse,
  RolesListResponseDto,
  UsergroupsResponseDto,
  PermissionsListResponseDto,
} from '@sociuu/interfaces';
import { IRoleDetailsServer, ISourcePayload } from '@lib/permissions.interface';
import { IAdminList, IAdminListServer, IAdminUser, IAdminUserServer } from '@lib/admin-users.interface';

@Injectable({
  providedIn: 'root',
})
export class RolesAndPermissionsService {
  sort_order = 'DESC';
  sort_by = 'created_at';

  private cancelGetAdminUserRequest$: Subject<void> = new Subject<void>();

  public cancelPreviousGetAdminUserRequest() {
    this.cancelGetAdminUserRequest$.next();
  }

  constructor(private http: HttpClient) {}

  getRolesAll(): Observable<RolesListResponseDto> {
    return this.http.get<RolesListResponseDto>(
      `${environment.apiUrl}/roles/all`
    );
  }

  getRoleDetails(id: number): Observable<IRoleDetailsServer> {
    let params = new HttpParams();
    params = params.append('role_id', `${id}`);
    return this.http.get<IRoleDetailsServer>(
      `${environment.apiUrl}/roles/role-details`,
      { params: params }
    );
  }

  createRole(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/roles/new-role`, data);
  }

  updateRole(data): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/roles/role-details`,
      data
    );
  }

  getUsers(): Observable<ListUsersResponse> {
    return this.http.get<ListUsersResponse>(
      `${environment.apiUrl}/users/get-users`
    );
  }

  saveRoleMembers(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/roles/members`, data);
  }


  saveRoleSources(data: ISourcePayload): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/roles/access/sources`, data);
  }

  getUserGroups(id: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('client_id', `${id}`);
    return this.http.get<any>(`${environment.apiUrl}/roles/groups`, {
      params: params,
    });
  }

  getUserGroupsForUserAccess(data?: {
    page: number;
    key?: string;
    per_page: number;
  }): Observable<UsergroupsResponseDto> {
    const { page, key, per_page } = data;
    let params = new HttpParams();
    params = params.append('page', `${page}`);
    params = params.append('pagination', `${per_page}`);
    params = params.append('sort_by', `${this.sort_by}`);
    params = params.append('sort_order', `${this.sort_order}`);

    if (key) {
      params = params.append('key', `${key}`);
    }
    return this.http.get<UsergroupsResponseDto>(
      `${environment.apiUrl}/usergroups/list?`,
      { params: params }
    );
  }

  saveRoleGroups(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/roles/access`, data);
  }

  getAllPermissions(): Observable<PermissionsListResponseDto> {
    return this.http.get<PermissionsListResponseDto>(
      `${environment.apiUrl}/roles/permissions/all`
    );
  }

  saveRolePermissions(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/roles/permissions`, data);
  }

  deleteRole(id: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('id', `${id}`);

    return this.http.delete<any>(`${environment.apiUrl}/roles/delete`, {
      params,
    });
  }

  public getAdminUsers(data?: { page: number; per_page: number; key?: string }): Observable<IAdminList> {
    let params = new HttpParams();
    const { page, per_page, key } = data;

    params = params.append('page', `${page}`);
    params = params.append('per_page', `${per_page}`);
    if (key) params = params.append('input', `${key}`);

    return this.http.get<IAdminListServer>(
      `${environment.apiUrl}/users/admins-list`,
      { params: params }
    ).pipe(
      takeUntil(this.cancelGetAdminUserRequest$),
      map((response: IAdminListServer): IAdminList => this.tranformAdminList(response))
    );
  }

  private tranformAdminList(response: IAdminListServer): IAdminList {
    return ({
      success: response.success,
      users: {
        to: response.users.to,
        from: response.users.from,
        path: response.users.path,
        total: response.users.total,
        perPage: response.users.per_page,
        lastPage: response.users.last_page,
        currentPage: response.users.current_page,
        data: this.tranformAdminUser(response.users.data),
      },
    });
  }

  private tranformAdminUser(data: IAdminUserServer[]): IAdminUser[] {
    return data.map((user: IAdminUserServer): IAdminUser => ({
      id: user.id,
      email: user.email,
      lastName: user.last_name,
      firstName: user.first_name,
      isEditable: user.is_editable,
      isExternal: user.is_external,
      isOptInEmailSent: user.is_opt_in_email_sent,
      canSendOptInEmail: user.can_send_opt_in_email,
      title: `${user.first_name} ${user.last_name}`,
    }));
  }
}
