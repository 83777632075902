import { isNil } from 'lodash';
import { environment } from '@utility/app.util';
import { BehaviorSubject, Observable, map, of, switchMap, tap } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';

import { ITagList, ITagListServer, ITag, ITagServer, ITagListCreateServer, ITagsParams } from '@lib/tag.interface';

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  private _tags: BehaviorSubject<ITagList> = new BehaviorSubject<ITagList>(null);

  public get tags(): BehaviorSubject<ITagList> {
    return this._tags;
  }

  constructor(private http: HttpClient) {}

  public getTags(keyword?: string): Observable<ITagList> {
    return this.tags.pipe(
      switchMap((response: ITagList): Observable<ITagList> => {
        if (!isNil(response)) return of(response);
        else {
          let params = new HttpParams();
          if (keyword) params = params.append('keyword', `${keyword}`);
          params = params.append('additional_info', `${true}`);

          return this.http.get<ITagListServer>(`${environment.apiUrl}/v2/tags`, { params })
            .pipe(
              map((response: ITagListServer): ITagList => this.transformTagList(response)),
              tap((response: ITagList) => this.tags.next(response)),
            );
        }
      })
    );
  }

  public getTagsV2(keyword?: string, query?: ITagsParams): Observable<ITagList> {
    const {
      page = 1,
      sort_by,
      pagination,
      sort_order,
      filterUserTags,
    } = query || {};

    let params = new HttpParams();

    params = params.append('page', page);
    params = params.append('additional_info', `${true}`);
    if (keyword) params = params.append('search', `${keyword}`);
    if (sort_by) params = params.append('sort_by', `${sort_by}`);
    if (pagination) params = params.append('per_page', `${pagination}`);
    if (sort_order) params = params.append('sort_order', `${sort_order}`);
    if (filterUserTags) params = params.append('filter_user_tags', `${filterUserTags}`);

    return this.http.get<ITagListServer>(`${environment.apiUrl}/v2/tags`, { params })
      .pipe(
        map(this.transformTagList.bind(this)),
        tap((response: ITagList) => this.tags.next(response)),
    );
  }

  public createTag(tag_name: string): Observable<{ data: ITag }> {
    let params = new HttpParams();
    params = params.append('additional_info', `${true}`);

    return this.http.post<ITagListCreateServer>(
      `${environment.apiUrl}/v2/tags`, { tag_name }, { params }
    )
    .pipe(
      map((response: ITagListCreateServer): { data: ITag } => {
        return { data: this.transformTag(response.data) }
      }),
      tap((response: { data: ITag }): void => {
        const tags: ITag[] = [...this.tags.value.data];
        tags.unshift(response.data);
        this.tags.next({
          ...this.tags.value,
          data: tags,
        })
      })
    );
  }

  public updateTag(data): Observable<ITag> {
    return this.http.put<ITagListCreateServer>(
      `${environment.apiUrl}/v2/tags/${data.tag_id}`, { tag_name: data.tag_name }
    ).pipe(
      map((response: ITagListCreateServer): ITag => {
        return this.transformTag(response.data)
      }),
      tap((response: ITag): void => {
        this.tags.next({
          ...this.tags.value,
          data: this.tags.value.data.map((tag: ITag): ITag => {
            return tag.id === response.id ? response : tag;
          })
        })
      })
    );
  }

  public deleteTag(tag_id: number): Observable<{ success: boolean; message: string }> {
    return this.http.delete<{ success: boolean; message: string }>(
      `${environment.apiUrl}/v2/tags/${tag_id}`
    ).pipe(
      tap(({ success }) => {
        if (!success) return;
        this.tags.next({
          ...this.tags.value,
          data: this.tags.value.data.filter((tag: ITag): boolean => tag.id !== tag_id)
        })
      })
    );
  }

  public deleteMultipleTags(tag_ids: number[]): Observable<{ success: boolean; message: string }> {
    let params = new HttpParams();
    params = params.append('id', `${tag_ids.toString()}`);

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { id: tag_ids }
    }

    return this.http.delete<{ success: boolean; message: string }>(
      `${environment.apiUrl}/v2/tags`, options
    ).pipe(
      tap(({ success }) => {
        if (!success) return;
        this.tags.next({
          ...this.tags.value,
          data: this.tags.value.data.filter((tag: ITag): boolean => !tag_ids.includes(tag.id))
        })
      })
    );;
  }

  public transformServerPayloadTags(tags: ITag[]): ITagServer[] {
    return tags.map((tag: ITag): ITagServer => ({
      id: tag.id,
      tag_name: tag.tagName,
      created_at: tag.createdAt,
      post_count: tag.postCount,
      updated_at: tag.updatedAt,
      media_count: tag.mediaCount,
      content_count: tag.contentCount,
      usergroup_count: tag.usergroupCount,
      total_post_count: tag.totalPostCount,
      users_unsubscribed: tag.usersUnsubscribed,
    }))
  }

  private transformTagList(response: ITagListServer): ITagList {
    return {
      data: response.data.map(this.transformTag.bind(this)),
      links: {
        first: response.links.first,
        last: response.links.last,
        prev: response.links.prev,
        next: response.links.next,
      },
      meta: {
        currentPage: response.meta.current_page,
        from: response.meta.from,
        lastPage: response.meta.last_page,
        path: response.meta.path,
        perPage: response.meta.per_page,
        to: response.meta.to,
        total: response.meta.total,
      }
    }
  }

  private transformTag(response: ITagServer): ITag {
    return {
      id: response.id,
      title: response.tag_name,
      tagName: response.tag_name,
      createdAt: response.created_at,
      ...(!isNil(response.client_id) && { clientId: response.client_id }),
      ...(!isNil(response.updated_at) && { updatedAt: response.updated_at }),
      ...(!isNil(response.post_count) && { postCount: response.post_count }),
      ...(!isNil(response.media_count) && { mediaCount: response.media_count }),
      ...(!isNil(response.content_count) && { contentCount: response.content_count }),
      ...(!isNil(response.usergroup_count) && { usergroupCount: response.usergroup_count }),
      ...(!isNil(response.total_post_count) && { totalPostCount: response.total_post_count }),
      ...(!isNil(response.users_unsubscribed) && { usersUnsubscribed: response.users_unsubscribed }),
    };
  }
}
