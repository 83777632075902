export default {
  /*
    SociuuHub platform pages
  */

  // Login
  "Sign In": "Sign In",
  "Email": "Email",
  "Password": "Password",
  "Submit": "Submit",
  "Forgot password?": "Forgot password?",
  "Reset password": "Reset password",
  "If you do not get an email from us within a few minutes be sure to check your spam filter.": "If you do not get an email from us within a few minutes be sure to check your spam filter.",
  "Back to login": "Back to login",

  // Magic Login Page
  "Request Magic Login": "Request Magic Login",
  "To make life easy we've skipped lengthy and difficult passwords. Enter your email and check your inbox for an email with a login link.": "To make life easy we've skipped lengthy and difficult passwords. Enter your email and check your inbox for an email with a login link.",
  "To make life easy we've skipped lengthy and difficult passwords. Enter your email or number and check your inbox for an email or message with a login link.": "To make life easy we've skipped lengthy and difficult passwords. Enter your email or number and check your inbox for an email or message with a login link.",
  "Email Address": "Email Address",
  "Email is required.": "Email is required.",
  "Invalid Email": "Invalid Email",
  "Enter email or mobile number": "Enter email or mobile number",
  "Email or number is required.": "Email or number is required.",
  "Email or number is invalid.": "Email or number is invalid.",
  "Send magic link": "Send magic link",
  "Welcome to your<br/>Content Hub": "Welcome to your<br/>Content Hub",
  "This is your personal Content Hub, where you will find our latest news, social media posts, and job listings that you can share with your network. Enter your email address to gain access.": "This is your personal Content Hub, where you will find our latest news, social media posts, and job listings that you can share with your network. Enter your email address to gain access.",

  // Magic Verify Page
  "Verifying user!": "Verifying user!",

  // Signup Page
  "Signup": "Signup",
  "Signin": "Signin",
  "Already have an account?": "Already have an account?",
  "First name": "First name",
  "Last name": "Last name",
  "Email address": "Email address",
  "Phone": "Phone",
  "Department": "Department",
  "Department is required": "Department is required",
  "I agree to the": "I agree to the",
  "Welcome to": "Welcome to",
  "Create your account": "Create your account",
  "Invalid phone number": "Invalid phone number",
  "Allowed domains i.e;": "Allowed domains i.e;",
  "Email address or Phone number is required": "Email address or Phone number is required",
  "Invalid domain": "Invalid domain",
  "Please enter a valid email address": "Please enter a valid email address",
  "Last name is required": "Last name is required",
  "Whitespaces are not allowed": "Whitespaces are not allowed",
  "First name is required": "First name is required",

  // Welcome modal
  "Hi {{ userName }}": "Hi {{ userName }}",
  "Welcome to our social media hub. Take part in sharing all our great stories, news, job posts and more.": "Welcome to our social media hub. Take part in sharing all our great stories, news, job posts and more.",
  "Every share counts.": "Every share counts.",
  "Get started right here": "Get started right here",

  /*
    Admin platform pages
  */
};
