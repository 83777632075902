import { Pipe, PipeTransform } from '@angular/core';
import { PERSONIO_URL_REGEX } from '@app/modules/admin/settings/settings-content-hub/create-source-new/create-source-new.component';

@Pipe({
  name: 'hostname',
})
export class HostnamePipe implements PipeTransform {
  validURL(str) {
    let result;
    let match;
    if (
      (match = str?.match(
        /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im
      ))
    ) {
      result = match[1];
      if ((match = result?.match(/^[^\.]+\.(.+\..+)$/))) {
        result = match[1];
      }
      return result;
    }
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  }

  private isValidPersonioURL(url: string) {
    return PERSONIO_URL_REGEX.test(url);
  }
  
  private getHostnameFromValidURL(url: string) {
    if (this.isValidPersonioURL(url)) {
      try {
        const urlObj: URL = new URL(url);
        return urlObj.hostname;
      } catch (error) {
        return url;
      }
    } else {
      return url;
    }
  }

  transform(value: any, ...args: any[]): any {
    if (value.includes('personio')) {
      const hostName: string = this.getHostnameFromValidURL(value);
      return hostName;
    } else {
      if (this.validURL(value)) {
        return new URL(value).hostname;
      } else {
        return value;
      }
    }
  }
}
