export function is12HoursFormat(): boolean {
  const userLocale = navigator.language || window.navigator['userLanguage'];
  const formatter: Intl.DateTimeFormat = new Intl.DateTimeFormat(userLocale, { hour: 'numeric', minute: 'numeric' });
  const formatInfo: Intl.DateTimeFormatPart[] = formatter.formatToParts(new Date());
  const timeFormat: Intl.DateTimeFormatPart = formatInfo.find(part => part.type === 'dayPeriod');

  if (timeFormat) {
    const formattedTime: string = timeFormat.value.toLowerCase();
    return (formattedTime === 'am' || formattedTime === 'pm');
  } else {
    console.warn('Unable to determine time format');
    return null;
  }
}

export const LAST_SYNC_FORMAT_24HR: string = 'YYYY-MM-DD HH:mm:ss';
export const LAST_SYNC_FORMAT_12HR: string = 'YYYY-MM-DD h:mm:ss A';