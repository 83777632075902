import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbsPipe} from './abs.pipe';

@NgModule({
  declarations: [AbsPipe],
  exports: [
    AbsPipe
  ],
  imports: [
    CommonModule
  ]
})

export class AbsPipeModule { }