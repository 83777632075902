import {
  Inject,
  OnInit,
  Component,
  ViewChild,
  TemplateRef,
  AfterViewInit,
  ChangeDetectorRef,
  ViewEncapsulation,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IConfirmationModalConfig } from './confirmation-modal.service';

@Component({
  selector: 'sociuu-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styles: [
    /* language=SCSS */
    `
      .fuse-confirmation-dialog-panel {
        @screen md {
          @apply w-128;
        }

        .mat-dialog-container {
          padding: 0 !important;
        }
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationModalComponent implements OnInit, AfterViewInit {
  @ViewChild('inputConfirmTemplate') public inputConfirmTemplate: TemplateRef<HTMLElement>;
  @ViewChild('checkboxConfirmTemplate') public checkboxConfirmTemplate: TemplateRef<HTMLElement>;
  @ViewChild('slideToggleConfirmTemplate') public slideToggleConfirmTemplate: TemplateRef<HTMLElement>;

  public confirmationInput: string;
  public confirmationCheck: boolean = false;
  public template: TemplateRef<HTMLElement> = null;

  public get isConfirmDisabled(): boolean {
    if (!this.data.template) return false;

    if (!this.data.template?.customTemplateForm) {
      if (this.data.template.value) return this.confirmationInput !== this.data.template.value;
    }
    else return this.data.template.customTemplateForm.invalid;
  }

  public get matDialogData(): any {
    if (this.data.template?.templateType === 'slideToggle') return { status: 'confirmed', data: this.confirmationCheck };
    else return 'confirmed';
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IConfirmationModalConfig,
    public matDialogRef: MatDialogRef<ConfirmationModalComponent>,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {}

  public preventPaste(event: ClipboardEvent): void {
    event.preventDefault();
  }

  public ngAfterViewInit(): void {
    this.setTemplate();
  }

  private setTemplate(): void {
    let template: TemplateRef<HTMLElement> = null;
    if (this.data.template) {
      switch (this.data.template.templateType) {
        case 'confirmInput': {
          template = this.inputConfirmTemplate;
          break;
        }
        case 'checkbox': {
          template = this.checkboxConfirmTemplate;
          break;
        }
        case 'slideToggle': {
          template = this.slideToggleConfirmTemplate;
          break;
        }
        case 'custom': {
          template = this.data.template.templateRef;
          break;
        }
        default: {
          template = null;
          break;
        }
      }
    }
    this.template = template;
    this.cdr.detectChanges();
  }
}
