import moment from "moment";

import { FormGroup } from "@angular/forms";
import { TemplateRef } from "@angular/core";

import { IScoreboardDetailsConfig } from "@app/modules/admin/scoreboard/details/scoreboard-details.component";

import { IConfirmationModalConfig } from "./confirmation-modal.service";

import { ITag } from "@lib/tag.interface";
import { IUser } from "@lib/users.interface";
import { ICompliance } from "@lib/self-signup.interface";
import { IVerifyEmail } from "@lib/user-bulk-delete.interface";
import { ISendoutConfirmation } from "@lib/sendout-settings.interface";

import { DATE_FORMAT_WITHOUT_SECONDS, toParagraphCase } from "@app/utility/app.util";
import { FuseConfirmationConfig } from "@fuse/services/confirmation";

export const DEFAULT_CONFIG: IConfirmationModalConfig = {
  title: 'Confirm action',
  message: 'Are you sure you want to confirm this action?',
  icon: {
    show: true,
    name: 'heroicons_outline:exclamation',
    color: 'warn',
  },
  actions: {
    confirm: {
      show: true,
      label: 'Confirm',
      color: 'warn',
    },
    cancel: {
      show: true,
      label: 'Cancel',
    },
  },
  dismissible: false,
  isConfirmActionDisabled: false,
}

export const deleteDepartmentConfig = (data: number[], templateRef: TemplateRef<HTMLElement>, form: FormGroup): IConfirmationModalConfig => {
  let message: string = `
    <p>
      Deleting ${data.length > 1 ? 'multiple departments' : 'a department'} will
      permanently remove ${data.length > 1 ? 'them' : 'it'} from the system.
    </p>
  `;

  return {
    title: `Delete Department`,
    message: message,
    icon: {
      show: true,
      name: 'heroicons_outline:exclamation',
      color: 'warn',
    },
    actions: {
      confirm: {
        show: true,
        label: 'Delete',
        color: 'warn',
      },
      cancel: {
        show: true,
        label: 'Cancel',
      },
    },
    dismissible: true,
    template: {
      templateType: 'custom',
      templateRef: templateRef,
      customTemplateForm: form
    },
  };
}

export const deleteRoleConfig = (data: { roles: any[], membersCount: number, roleName?: string }, templateRef: TemplateRef<HTMLElement>, form: FormGroup): IConfirmationModalConfig => {
 let name = data.roles.length > 1 ? 'multiple roles' : ('role '+data.roleName || 'role');
  let message: string = `
    <p>
      Are you sure you want to delete ${name} ?
    </p>
    ${data.membersCount ? `</br>`: ''}
    <p>
      ${data.membersCount ? `Please note that all members currently assigned to this role
      must be moved to another role before proceeding.` : ''}
      Deleting a role is a permanent action and cannot be
      undone.
    </p>
    </br>
  `;

  return {
    title: `Delete Role`,
    message: message,
    icon: {
      show: true,
      name: 'heroicons_outline:exclamation',
      color: 'warn',
    },
    actions: {
      confirm: {
        show: true,
        label: 'Delete',
        color: 'warn',
      },
      cancel: {
        show: true,
        label: 'Cancel',
      },
    },
    dismissible: true,
    template: {
      templateType: 'custom',
      templateRef: templateRef,
      customTemplateForm: form
    },
  };
}

export const duplicateRolePermission = (templateRef: TemplateRef<HTMLElement>, form: FormGroup): IConfirmationModalConfig => {
  let message: string = `
    <p>
      You are about to duplicate permissions from other roles.
      This action will overwrite all existing permissions for the
      selected role.
    </p>
    </br>
  `;

  return {
    title: `Duplicate permissions`,
    message: message,
    icon: {
      show: true,
      name: 'heroicons_outline:exclamation',
      color: 'warn',
    },
    actions: {
      confirm: {
        show: true,
        label: 'Duplicate',
        color: 'warn',
      },
      cancel: {
        show: true,
        label: 'Cancel',
      },
    },
    dismissible: true,
    template: {
      templateType: 'custom',
      templateRef: templateRef,
      customTemplateForm: form
    },
  };
}

export const sendoutConfirmationConfig = (data: ISendoutConfirmation): IConfirmationModalConfig =>  {
  data.pulsing = data.pulsing.split('_').join(' ');
  let message: string = `
    <div>The selected post has Pulsing enabled. Pulsing setting is set to sent
      ${data.pulsingValue} ${data.pulsingValue > 1 ? 'messages' : 'message'} ${data.pulsing}
    </div>
  `;
  if (data.startingDate) message += `
    <div>Estimated date for first email: ${moment(data.startingDate).format(DATE_FORMAT_WITHOUT_SECONDS)}</div>
  `;
  if (data.closingDate) message += `
    <div>Estimated date for last email: ${moment(data.closingDate).format(DATE_FORMAT_WITHOUT_SECONDS)}</div>
  `;

  return {
    title: `Pulsing enabled`,
    message: message,
    icon: {
      show: true,
      name: 'heroicons_outline:exclamation',
      color: 'info',
    },
    actions: {
      confirm: {
        show: true,
        label: 'Send now',
        color: 'primary',
      },
      cancel: {
        show: true,
        label: 'Send with pulsing',
      },
    },
    dismissible: true,
  };
}

export const resendEmailsConfirmationConfig = (): IConfirmationModalConfig => {
  return {
    title: `Resend Email?`,
    message: `Are you sure you want to resend email to all non-opener users?`,
    icon: {
      show: true,
      color: 'warn',
      name: 'heroicons_outline:exclamation',
    },
    actions: {
      confirm: {
        show: true,
        label: 'Yes',
        color: 'primary',
      },
      cancel: {
        show: true,
        label: 'No',
      },
    },
    dismissible: true,
  }
};

export const schedulePostSendoutConfirmationConfig = (): IConfirmationModalConfig => {
  return {
    title: `Send-out Schedule Conflict`,
    message: `
      <div>The current date and time do not align with the specified send-out schedule for this post.</div>
      <br />
      <div>Do you wish to procced sending now or schedule for a later date and time?</div>
    `,
    icon: {
      show: true,
      name: 'heroicons_outline:exclamation',
      color: 'info',
    },
    actions: {
      confirm: {
        show: true,
        label: 'Send now',
        color: 'primary',
      },
      cancel: {
        show: true,
        label: 'Cancel',
      },
    },
    dismissible: true,
  }
};

export const deleteScoreboardConfirmationConfig = (details: IScoreboardDetailsConfig): IConfirmationModalConfig => {
  return {
    title: `Delete scoreboard?`,
    message: `Do you want to delete <b>${details.title}</b> scoreboard?`,
    icon: {
      show: true,
      color: 'warn',
      name: 'heroicons_outline:exclamation',
    },
    actions: {
      confirm: {
        show: true,
        label: 'Yes',
        color: 'primary',
      },
      cancel: {
        show: true,
        label: 'No',
      },
    },
    dismissible: true,
  }
};

export const deleteScorecardConfirmationConfig = (details: { title: string }): IConfirmationModalConfig => {
  return {
    title: `Delete scoreboard?`,
    message: ` Deleting a <b>${details.title}</b> will permanently remove it from system.`,
    icon: {
      show: true,
      color: 'warn',
      name: 'heroicons_outline:exclamation',
    },
    actions: {
      confirm: {
        show: true,
        label: 'Yes, Delete Scoreboard',
        color: 'primary',
      },
      cancel: {
        show: true,
        label: 'No, Keep Scoreboard',
      },
    },
    dismissible: true,
  }
};

export const deleteTagConfirmationConfig = (data: ITag): IConfirmationModalConfig => {
  let message: string = `
    <p>
      Deleting a <span class="bold">${data.tagName}</span> will permanently remove it from system.
    </p>
  `;

  return {
    title: `Delete Tag`,
    message: message,
    icon: {
      show: true,
      name: 'heroicons_outline:exclamation',
      color: 'warn',
    },
    actions: {
      confirm: {
        show: true,
        label: 'Delete',
        color: 'warn',
      },
      cancel: {
        show: true,
        label: 'Cancel',
      },
    },
    dismissible: true,
  };
}

export const usersBulkDeleteConfirmationConfig = (response: IVerifyEmail): IConfirmationModalConfig => ({
  title: 'Delete Users',
  message: `Are you sure you want to delete ${response.deleteCount} users permanently? <span class="font-medium">This action cannot be undone!</span>`,
  icon: {
    show: true,
    name: 'heroicons_outline:exclamation',
    color: 'warn',
  },
  actions: {
    confirm: {
      show: true,
      label: 'Delete',
      color: 'warn',
    },
    cancel: {
      show: true,
      label: 'Cancel',
    },
  },
  dismissible: true,
  template: {
    templateType: 'confirmInput',
    value: ''+response.deleteCount,
  }
});

export const userImportV2RemoveUsersConfirmation = (response: any): IConfirmationModalConfig => ({
  title: `Remove existing users?`,
  message: `Do you want to Remove existing users who are not in your import file?`,
  icon: {
    show: true,
    color: 'warn',
    name: 'heroicons_outline:exclamation',
  },
  actions: {
    confirm: {
      show: true,
      label: 'Yes',
      color: 'primary',
    },
    cancel: {
      show: true,
      label: 'No',
    },
  },
  dismissible: true,
  template: {
    templateType: 'confirmInput',
    value: 'DELETE ME',
  }
});

export const acceptOptInUserConfirmationConfig = (multiple: boolean = false, isSendWelcomeEmailEnabled: boolean): IConfirmationModalConfig => {
  return {
    title: `Forcefully Accept Opt-In User`,
    message: `
      <div>
        You are going to forcefully add ${multiple ? 'the selected users' : 'this user'} into your system.&nbsp
        ${multiple ? 'The selected users have' : 'This user has'} already received the Opt-in email.
      </div>
      <br/>
      <div>Do you still wish to procced with forcefully adding the ${multiple ? 'selected users' : 'current user'}?</div>
    `,
    icon: {
      show: true,
      name: 'heroicons_outline:exclamation',
      color: 'warning',
    },
    actions: {
      confirm: {
        show: true,
        label: 'Confirm',
        color: 'primary',
      },
      cancel: {
        show: true,
        label: 'Cancel',
      },
    },
    dismissible: true,
    ...(isSendWelcomeEmailEnabled && {
      template: {
        title: 'Send Welcome email',
        templateType: 'slideToggle',
      }
    }),
  }
};

export const welcomeEmailConfirmationConfig = (multiple: boolean = false): IConfirmationModalConfig => {
  return {
    title: `Send Welcome Email`,
    message: `
      <div>${multiple ? 'The selected users have' : 'This user has'} already recevied the the Welcome email.</div>
      <div>Do you still wish to procced sending the Welcome email again.</div>
    `,
    icon: {
      show: true,
      name: 'heroicons_outline:exclamation',
      color: 'warning',
    },
    actions: {
      confirm: {
        show: true,
        label: 'Confirm',
        color: 'primary',
      },
      cancel: {
        show: true,
        label: 'Cancel',
      },
    },
    dismissible: true,
  };
}

export const optInEmailConfirmationConfig = (multiple: boolean = false): IConfirmationModalConfig => {
  return {
    title: `Send Opt-In Email`,
    message: `
      <div>${multiple ? 'The selected users have' : 'This user has'} already recevied the the Opt-in email.</div>
      <div>Do you still wish to procced sending the Opt-in email again.</div>
    `,
    icon: {
      show: true,
      name: 'heroicons_outline:exclamation',
      color: 'warning',
    },
    actions: {
      confirm: {
        show: true,
        label: 'Confirm',
        color: 'primary',
      },
      cancel: {
        show: true,
        label: 'Cancel',
      },
    },
    dismissible: true,
  };
}

export const sendActivationConfirmationConfig = (): IConfirmationModalConfig => {
  return {
    title: `Send activation mail`,
    message: `
      You have successfully added a new admin. Would you like to send an activation email now or defer it to later time?
    `,
    icon: {
      show: false,
      name: 'heroicons_outline:exclamation',
      color: 'info',
    },
    actions: {
      confirm: {
        show: true,
        label: 'Send Activation Email Now',
        color: 'primary',
      },
      cancel: {
        show: true,
        label: 'Send Later',
      },
    },
    dismissible: true,
  }
};

export const becomeSociuuHubUserConfirmationConfig = (user: IUser): IConfirmationModalConfig => {
  return {
    title: `Login as ${user.first_name} to SociuuHub`,
    message: `Please be aware that you will be accessing ${user.first_name} ${user.last_name}'s Soiuuhub account.<br />
      All actions will be reflected on ${user.first_name}'s Sociuuhub account.<br /><br />
      Your access will last 30 minutes where you need to login again.<br />
      <span class="font-medium">By confirming this dialog you agree on the terms</span>`,
    icon: {
      show: true,
      name: 'heroicons_outline:exclamation',
      color: 'warning',
    },
    actions: {
      confirm: {
        show: true,
        label: 'Login',
        color: 'primary',
      },
      cancel: {
        show: true,
        label: 'Cancel',
      },
    },
    dismissible: false,
  }
};

export const lowCreditBalanceConfirmationConfig = (message: string): IConfirmationModalConfig => {
  return {
    title: 'Low credit balance',
    message: message,
    icon: {
      show: true,
      name: 'heroicons_outline:exclamation',
      color: 'info',
    },
    actions: {
      confirm: {
        show: true,
        label: 'Save as draft',
        color: 'primary',
      },
      cancel: {
        show: true,
        label: 'Close',
      },
    },
    dismissible: true,
  }
}

export const deleteMediaFileConfiramtionConfig = (): IConfirmationModalConfig => {
  return {
    title: 'Remove file',
    message: 'Are you sure you want to remove this permanently? <span class="font-medium">This action cannot be undone!</span>',
    icon: {
      show: true,
      name: 'heroicons_outline:exclamation',
      color: 'warn',
    },
    actions: {
      confirm: {
        show: true,
        label: 'Remove',
        color: 'warn',
      },
      cancel: {
        show: true,
        label: 'Cancel',
      },
    },
    dismissible: true,
  }
}

export const showComplianceContentConfig = (compliance: ICompliance): IConfirmationModalConfig => {
  return {
    title: toParagraphCase(compliance.compliance.title),
    message: compliance.compliance.contents,
    icon: { show: false },
    actions: {
      confirm: { show: false },
      cancel: {
        show: true,
        label: 'Close',
      },
    },
    dismissible: true,
  }
}

export const signupUserConfirmationConfig = (message: string): IConfirmationModalConfig => {
  return {
    title: 'Signup successful',
    message: message || `Welcome aboard! You're now officially part of our community. Get ready for exciting updates and offers.`,
    icon: {
      show: true,
      name: 'feather:user-check',
      color: 'success',
    },
    actions: {
      confirm: { show: false },
      cancel: {
        show: true,
        label: 'Close',
      },
    },
    dismissible: true,
  }
}

export const publishSocialConnectContentConfirmationConfig = (url: string, isVideoOrDocumentContent: boolean): IConfirmationModalConfig => {
  return {
    title: isVideoOrDocumentContent ? `Successfully published!` : `Successfully Posted!`,
    message: isVideoOrDocumentContent ? `
      <p>
        Hang tight for just a few more seconds while we upload the Media file. 
        You will receive an email, as soon as it is live on LinkedIn.
      </p>` : `
      <div>
        Your post has been published
      </div>
      <a href="${url}" target="_blank" class="text-blue-600 underline">
        See post
      </a>
    `,
    icon: {
      show: true,
      name: 'mat_solid:celebration',
      color: 'success',
    },
    actions: {
      confirm: {
        show: true,
        label: 'Close',
        color: 'primary',
      },
      cancel: {
        show: false,
        label: 'Cancel',
      },
    },
    dismissible: true,
  }
};

export const publishNativeContentConfirmationConfig = (type: 'create' | 'update' = 'create'): IConfirmationModalConfig => {
  const title: string = type === 'create' ? `Content Created and Ready for Sharing` :  `Content Updated and Ready for Sharing`;
  return {
    title: title,
    message: `
      <div>Your ${type === 'create' ? 'new content' : 'content has been updated and'} is now live in SociuuHub and ready for employees to share.</div>
      <div>Boost engagement by promoting to your colleagues.</div>
    `,
    icon: {
      show: true,
      name: 'mat_solid:celebration',
      color: 'success',
    },
    actions: {
      confirm: {
        show: true,
        label: 'Share to your colleagues',
        color: 'primary',
      },
      cancel: {
        show: true,
        label: 'See Content',
      },
    },
    dismissible: false,
  }
};

export const updateNativeConentConfirmationDialogConfig: FuseConfirmationConfig = {
  title: `Confirm Changes`,
  message: `
    Editing this content may affect future shares by employees and may cause discrepancies with previously sent contnent messages and previews.
    </br>
    Please ensure any changes align with your intended messaging strategy.
    </br>
    </br>
    By confirming, you acknowledge that you understand and accept the potential impacts of these changes.
  `,
  icon: {
    show: true,
    color: 'warning',
    name: 'heroicons_outline:exclamation',
  },
  actions: {
    confirm: {
      show: true,
      label: 'Confirm',
      color: 'primary',
    },
    cancel: {
      show: true,
      label: 'Cancel',
    },
  },
  dismissible: true,
}

export const connectToLinkedInConfirmationConfig = (): IConfirmationModalConfig => {
  return {
    title: `Connect to LinkedIn to Share Content`,
    message: `
      <div>It looks like you are not connected to Linkedin, which is required to share this post.
      Please Connect to LinkedIn to start sharing your content seamlessly.</div>
    `,
    icon: {
      show: true,
      name: 'heroicons_outline:exclamation',
      color: 'warning',
    },
    actions: {
      confirm: {
        show: true,
        label: 'Connect to LinkedIn to Share Content',
        color: 'primary',
      },
      cancel: {
        show: true,
        label: 'Close',
      },
    },
    dismissible: false,
  }
};
