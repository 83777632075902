import { environment } from '@utility/app.util';
import { BehaviorSubject, Observable, Subject, map, takeUntil } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { IBrandWatch } from './brand-width-modal/brand-width-modal.component';

import {
  ContentHubPost,
  ContentHubReadParams,
  ContentSourceTypeModel,
  NetworksResponseDto,
  Tag,
  UsergroupsResponseDto
} from '@sociuu/interfaces';
import { AddTagsPayload, ITag } from '@lib/tag.interface';

@Injectable({
  providedIn: 'root',
})
export class ContentHubService {
  constructor(private http: HttpClient) { }

  private cancelGetPostsRequest$: Subject<void> = new Subject<void>();

  public cancelPreviousGetPostsRequest() {
    this.cancelGetPostsRequest$.next();
  }

  public getPosts(query?): Observable<{ data }> {
    if (query) {
      const {
        sources,
        tags,
        type_ids,
        date_from,
        date_to,
        group_by,
        sort_order,
        is_archived,
        key,
        page,
        per_page,
        show_posts,
        user_groups,
        networks,
        statuses,
        show_contents,
        show_native,
      } = query;
      let params = new HttpParams();

      if (sources && sources.length > 0) {
        params = params.append('sources', sources);
      }

      if (tags && tags.length > 0) {
        params = params.append('tags', tags);
      }

      if (user_groups && user_groups.length > 0) {
        params = params.append('user_groups', user_groups);
      }

      if (type_ids && type_ids !== 'all') {
        params = params.append('type_ids', type_ids);
      }

      if (date_from && date_from !== '-1') {
        params = params.append('date_from', date_from);
      }

      if (date_to && date_to !== '-1') {
        params = params.append('date_to', date_to);
      }

      if (group_by) {
        params = params.append('group_by', group_by);
      }

      if (sort_order) {
        params = params.append('sort_order', sort_order);
      }

      if (is_archived) {
        params = params.append('is_archived', is_archived);
      }

      if (key && key !== '') {
        params = params.append('key', key);
      }

      if (networks && networks !== '') {
        params = params.append('networks', networks);
      }

      if (statuses && statuses !== '') {
        params = params.append('statuses', statuses);
      }

      params = params.append('with_pagination', 'yes');
      params = params.append('page', page);
      params = params.append('per_page', `${per_page}`);
      params = params.append('show_posts', show_posts);
      params = params.append('show_native', show_native);
      params = params.append('show_contents', show_contents);

      return this.http.get<{ data: { data: ContentHubPost[] } }>(`${environment.apiUrl}/content-source/get-contents`, { params })
        .pipe(
          takeUntil(this.cancelGetPostsRequest$),
          map((response: { data: { data: ContentHubPost[] } }) => ({
            ...response,
            data: {
              ...response.data,
              data: !response.data.data ? [] : response.data.data?.map((item: ContentHubPost) => ({
                ...item,
                tags: item.tags?.map((tag: any): ITag => ({
                  id: +tag.id,
                  title: tag.tag_name,
                  tagName: tag.tag_name,
                }))
              }))
            }
          }))
        );
    }
    return this.http.get<{ data }>(
      `${environment.apiUrl}/content-source/get-contents`
    );
  }


  getSources(): Observable<{ data: ContentSourceTypeModel }> {
    return this.http.get<{ data: ContentSourceTypeModel }>(
      `${environment.apiUrl}/content-source`
    );
  }

  getTags(): Observable<{ data: Tag[]; success: boolean }> {
    return this.http.get<{ data: Tag[]; success: boolean }>(
      `${environment.apiUrl}/v2/tags`
    );
  }

  updateContentTags(
    payload: AddTagsPayload,
    contentType: string,
    contentId: number
  ): Observable<any> {
    return this.http.put<any>(
      `${environment.apiUrl}/content-source/update-content-tags/${contentType}/${contentId}`,
      payload
    );
  }

  refreshPosts(): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/content-source/refresh`,
      ''
    );
  }

  getClientNetworks(): Observable<NetworksResponseDto> {
    return this.http.get<NetworksResponseDto>(
      `${environment.apiUrl}/client/getNetworks`
    );
  }

  getRefresh() {
    return this.http.get<any>(`${environment.apiUrl}/content-source/get-refresh`)
      .pipe(takeUntil(this.cancelGetPostsRequest$));
  }

  public archivePosts(data, postStatus) {
    const status = postStatus ? 'unarchive' : 'archive';
    return this.http.put<any>(
      `${environment.apiUrl}/v2/posts/${status}`,
      { ...data }
    );
  }

  public archiveContents(data, postStatus) {
    const status = postStatus ? 'unarchive' : 'archive';
    return this.http.put<any>(
      `${environment.apiUrl}/v2/contents/${status}`,
      { ...data }
    );
  }

  updateContent(payload: any, contentId: number): Observable<any> {
    return this.http.put<any>(
      `${environment.apiUrl}/content-source/update/content/${contentId}`,
      payload
    );
  }

  updateContentImage(payload: any): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/content-source/update-content-image`,
      payload
    );
  }

  getUsergroups(currentPage: number, loadMore: boolean = false, key: string = ''): Observable<UsergroupsResponseDto> {
    let params = new HttpParams();
    params = params.append('page', `${currentPage}`);
    if (key) params = params.append('key', key);

    return this.http.get<UsergroupsResponseDto>(
      `${environment.apiUrl}/usergroups/list?`,
      { params: params }
    );
  }

  updatePostTags(
    payload: AddTagsPayload,
    contentId: number,
    type: string
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/content-source/update-content-tags/${type}/${contentId}`,
      payload
    );
  }

  public pushToBrandwatch(stocks: IBrandWatch) {
    return this.http.post<any>(`${environment.apiUrl}/content-source/send-to-brandwatch`, stocks);
  }

}
