<form [formGroup]="form">
    <div>
        <div class="flex">
            <div class="flex w-2/4 border-grey align-items-center p-5">
                <strong class="pl-10"> Filter by usergroups </strong>
            </div>
            <div
                class="flex justify-content-sb w-2/4 border-grey align-items-center p-1"
            >
                <mat-checkbox (change)="selectAllUsers($event)" class="ml-3">
                    <strong> Users </strong>
                    <label class="m-badge m-badge-accent" *ngIf="users">{{
                        users?.length
                    }}</label>
                </mat-checkbox>
                <mat-form-field
                    class="mr-3"
                    appearance="outline"
                    style="margin-bottom: -1.25em"
                >
                    <mat-label>Search for users</mat-label>
                    <input
                        matInput
                        type="text"
                        formControlName="search"
                        #userSearch
                    />
                    <button
                        mat-button
                        *ngIf="userSearch.value"
                        matSuffix
                        mat-icon-button
                        (click)="
                            userSearch.value = ''; searchUser(userSearch.value)
                        "
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
        </div>

        <div class="flex">
            <div class="f-item w-2/4 border-grey height-300 overflow-auto">
                <div
                    *ngFor="let group of usergroups; let i = index"
                    class="flex justify-content-sb w-full p-3"
                >
                    <section class="f-item">
                        <mat-checkbox
                            (change)="
                                selectUsergroupsOnCheckboxChange($event);
                                getUsersFromUsergroups()
                            "
                            [checked]="checkUserGroupsSelected(group)"
                            [value]="group"
                        >
                          <div class="flex items-center">
                            {{ group.title }}
                            <span *ngIf="group && group.is_external" [matTooltip]="'Actimo Usergroup'" class="w-4 ml-3">
                              <img src="assets/theme/icons/actimo-logo.png" alt="Actimo logo">
                            </span>
                          </div>
                        </mat-checkbox>
                    </section>
                    <div class="m-badge m-badge-accent">
                        {{ group.user_count }}
                    </div>
                </div>
            </div>
            <div class="f-item w-2/4 border-grey overflow-auto">
                <cdk-virtual-scroll-viewport
                    appendOnly
                    itemSize="44"
                    class="flex justify-content-sb p-3 height-300"
                >
                    <div
                        *cdkVirtualFor="let user of users; let i = index"
                        class="flex justify-content-sb p-3"
                    >
                        <section class="f-item">
                            <mat-checkbox
                              (change)="selectUsersOnCheckboxChange($event)"
                              [checked]="checkUserSelected(user)"
                              [value]="user"
                            >
                              <div class="flex items-center">
                                {{ user.first_name }}
                                {{ user.last_name }}
                                <span *ngIf="user && user.is_external" [matTooltip]="'Actimo User'" class="w-4 ml-3">
                                  <img src="assets/theme/icons/actimo-logo.png" alt="Actimo logo">
                                </span>
                              </div>
                            </mat-checkbox>
                        </section>
                    </div>

                    <div *ngIf="!users?.length" class="text-center">
                        No users available
                    </div>
                </cdk-virtual-scroll-viewport>
            </div>
        </div>
    </div>

    <div class="mt-7">
        <h4>
            Selected users
            <label class="m-badge m-badge-primary" *ngIf="users">{{
                form.value.users?.length
            }}</label>
        </h4>
        <div class="user-badges-list">
            <mat-chip-list>
                <mat-chip
                    color="primary"
                    selected
                    *ngFor="let user of form.value.users"
                    (removed)="unselectUser(user)"
                    >
                    <span class="mr-1">{{ user.first_name }} {{ user.last_name }}</span>
                    <mat-icon svgIcon="mat_solid:cancel" matChipRemove></mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>
</form>
