import { Injectable } from "@angular/core"
import { Locale } from "./transloco.http-loader"
import { TranslocoService } from "@ngneat/transloco";

@Injectable({ providedIn: 'root' })
export class I18nService {
  public locale: string = Locale.ENGLISH_USA;

  constructor(private translateService: TranslocoService) {
    this.locale = navigator.language || Locale.ENGLISH_USA;
    this.translateService.setActiveLang(this.locale);
  }

  public setLocale(userLang: string): void {
    this.locale = userLang;
    this.translateService.setActiveLang(this.locale);
  }

  public getLocale(): string {
    return this.locale;
  }
}
