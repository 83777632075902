import { Component, Input, OnInit } from '@angular/core';
import { PermissionsEnum, UserPermissions } from '@app/app.permissions';
import { AppService } from '@app/app.service';
import { CoreComponent } from '@sociuu/core';
import { CountSettingsDto, UserInfoDto } from '@sociuu/interfaces';
import { AuthService, DashboardService } from '@sociuu/modules';
import { Observable } from 'rxjs';

interface SidebarMenuItem {
  route?: string;
  caption: string;
  children?: SidebarMenuItem[];
  openSubMenu?: boolean;
  icon?: string;
  callback?(): void;
}

@Component({
  selector: 'sociuu-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent extends CoreComponent implements OnInit {
  userInfo: UserInfoDto;
  @Input() showWebhookPosts: Observable<boolean>;
  @Input('menuItems') menuItems: SidebarMenuItem[] = [
    {
      caption: 'Dashboard',
      route: '/dashboard',
    },
    {
      caption: 'Content Hub',
      route: '/content-hub',
    },
    {
      caption: 'Create post',
      route: '/posts/create',
    },
    {
      caption: 'Posts',
      children: [
        {
          caption: 'Regular Posts',
          route: '/posts/regular',
        },
        {
          caption: 'Webhook Posts',
          route: '/posts/webhook',
        },
        {
          caption: 'Sociuu Hub',
          route: '/posts/sociuuhub',
        },
      ],
      openSubMenu: false,
      callback(): void {
        this.openSubMenu = !this.openSubMenu;
      },
    },
    {
      caption: 'Scheduled posts',
      route: '/posts/schedule',
    },
    {
      caption: 'Draft posts',
      route: '/posts/my-drafts',
    },
    {
      caption: 'User center',
      children: [
        {
          caption: 'All users',
          route: '/users',
        },
        {
          caption: 'User groups',
          route: '/user-groups',
        },
        {
          caption: 'Import users',
          route: '/users/import',
        },
        {
          caption: 'Sync users',
          route: '/sync-users',
        },
      ],
      openSubMenu: false,
      callback(): void {
        this.openSubMenu = !this.openSubMenu;
      },
    },
    {
      caption: 'Tags',
      route: '/tags',
    },
    {
      caption: 'Insights & Statistics',
      children: [
        {
          caption: 'Overview',
          route: '/insights',
        },
        {
          caption: 'Benchmark',
          route: '/insights/benchmark',
        },
        {
          caption: 'Engagement',
          route: '/insights/engagement',
        },
        {
          caption: 'Post Effectiveness',
          route: '/insights/post-effectiveness',
        },
        {
          caption: 'User Group Stats',
          route: '/insights/usergroup-stats',
        },
      ],
      openSubMenu: false,
      callback(): void {
        this.openSubMenu = !this.openSubMenu;
      },
    },
    {
      caption: 'Site Settings',
      route: '/settings',
      icon: 'settings',
    },
    {
      caption: 'Roles & Permissions',
      route: '/security/roles',
      icon: 'security',
    },
  ];

  public usersCount: number;

  constructor(
    private authService: AuthService,
    private appService: AppService,
  ) {
    super();
  }
  
  ngOnInit(): void {
    this.appService.getUserCount().subscribe({
      next: (count: number) => {
        this.usersCount = count;
      },
    })
    this.userInfo = this.authService.currentUser.value;
    if (!UserPermissions[PermissionsEnum.Fullprivilege]) {
      this.menuItems = this.menuItems.filter(
        (item) => item.caption !== 'Roles & Permissions'
      );
    }
  }
}
