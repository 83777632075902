import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DatepickerWithTimeComponent } from './datepicker-with-time.component';

@NgModule({
  declarations: [DatepickerWithTimeComponent],
  imports: [
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: [DatepickerWithTimeComponent]
})
export class MatDatepickerTimeHeaderModule { }
