import { finalize } from 'rxjs/operators';
import { environment } from '@utility/app.util';
import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { PersonioDomains } from '../settings-content-hub/create-source-new/create-source-new.component';

import {
  IntegrationDto,
  SocialIntegrationDto,
  VideosFromNetworkResponseDto,
  PagesFromSocialNetworkResponseDto,
} from '@sociuu/interfaces';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {

  _videoLoading = new BehaviorSubject<boolean>(false);
  videoLoading = this._videoLoading.asObservable();

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  public getVideosPages(networkId: number, urn: number, userId: number): Observable<VideosFromNetworkResponseDto> {
    this._videoLoading.next(true);
    return this.http.get<VideosFromNetworkResponseDto>(`${environment.apiUrl}/social/get-video-posts/${networkId}/${userId}/${urn}`)
    .pipe(
      finalize( () => {
        this._videoLoading.next(false);
      })
    );
  }

  public getSocialIntegrations(id: number): Observable<SocialIntegrationDto> {
    return this.http.get<SocialIntegrationDto>(`${environment.apiUrl}/content-source/social-integrations/${id}`);
  }

  public getPagesFromSocialNetwork(userId: number, networkId: number): Observable<PagesFromSocialNetworkResponseDto> {
    return this.http.get<PagesFromSocialNetworkResponseDto>(`${environment.apiUrl}/social/get-company-pages/${networkId}/${userId}`);
  }

  public createFalconApiKey(data){
    return this.http.post<any>(`${environment.apiUrl}/client/integration`, data);
  }

  public getIntegration(): Observable<IntegrationDto> {
    return this.http.get<IntegrationDto>(`${environment.apiUrl}/client/integration`);
  }

  public deleteIntegration(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.apiUrl}/client/integration/${id}`);
  }

  public verifyPerisoClient(name: string, domain: PersonioDomains): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/v2/sources/personio/verify-account-name`,
      {
        account_name: name,
        account_domain: domain,
      }
    );
  }

  public verifyPerisoURL(name: string, domain: PersonioDomains, url: string): Observable<any> {
     return this.http.post<any>(`${environment.apiUrl}/v2/sources/personio/verify-job-url`,
       {
        account_name: name,
        account_domain: domain,
        url
      }
    );
  }
}

